// Events
// ===================================

#tribe-events-content-wrapper #tribe-events-content .tribe-events-tooltip h4, .single-tribe_events a.tribe-events-ical, .single-tribe_events a.tribe-events-gcal {
  color: $color-light-grey;
}

.tribe-events-ical.tribe-events-button {
  color: $color-light-grey;
}

.tribe-events-event-image img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  margin-bottom: 15px;
}
