$beer-header-height: 100px;

$beer-header-primary-height: 100px;
$beer-header-secondary-height: 70px;

$bf-sticky-height: $beer-header-primary-height + $beer-header-secondary-height;
$bf-sticky-height-xs: $beer-header-primary-height;

@keyframes loadingMessage {
  0%   {
    color: $brand-tertiary;
  }
  50%  {
    color: mix($brand-tertiary, white, 50%);
  }
  100% {
    color: $brand-tertiary;
  }
}

.page-template-template-beer-finder {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
}

.beer-finder-header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1010;
  height: $bf-sticky-height;

  @media ( max-width: $screen-sm-max ) {
    // position: fixed;
    height: $bf-sticky-height-xs;
  }
}

.beer-finder-header-primary {
  position: relative;
  // top: 0;
  // left: 0;
  // width: 100%;
  height: $beer-header-primary-height;
  z-index: 1015;
  background-color: white;
  box-shadow: 0px 2px 0px rgba(#0b0201, 0.1);
}

.beer-finder-header-secondary {
  @media ( min-width: $screen-md-min ) {
    position: relative;
    background-color: mix($border-color, white, 50%);
    height: $beer-header-secondary-height;
    box-shadow: 0px 2px 0px rgba(#0b0201, 0.1);
    padding: 15px 0;
  }
}


.beer-finder-header-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;

  @media ( max-width: $screen-sm-max ) {
    flex-wrap: nowrap;
  }

  .beer-finder-header-back,
  .beer-finder-search {
    z-index: 5;
  }

  .beer-finder-filters-controls,
  .beer-finder-filters {
    z-index: 10;
  }
}

.beer-finder-header-title {
  font-size: 20px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;

  @media ( max-width: $screen-sm-max ) {
    padding: 10px;
  }

  > span {
    display: block;
    font-family: $font-family-brand-sc;
  }
}

.inner-col {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.inner-col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.inner-col-fill {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.beer-finder-header-back {
  display: flex;
  align-items: center;

  .back-button {
    display: flex;
    padding: 10px 12px;
    position: relative;
    align-items: center;
    height: 54px;

    .fa {
      // display: inline-block;
      margin-right: 30px;

      @media ( max-width: $screen-sm-max ) {
        margin-right: 15px;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 2px;
      background-color: $border-color;
      height: 54px;
    }
  }
}

.beer-finder-search {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;

  @media ( min-width: $screen-md-min ) {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @media ( max-width: $screen-sm-max ) {
    position: absolute;
    top: $bf-sticky-height-xs;
    left: 0;
    right: 0;
    padding-left: 15px;
    width: auto;
    transform: translateY(-50%);
    opacity: 0;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, max-height 0s linear 0.2s;
    overflow: hidden;
    max-height: 0px;
    background-color: mix($border-color, white, 50%);
    box-shadow: 0px 2px 0px rgba(#0b0201, 0.1);

    &.show-search {
      opacity: 1;
      transform: translateY(0);
      max-height: 100px;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, max-height 0s linear;
    }
  }

  .form-control {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 30px;
    border-color: mix(white, $border-color, 50%);
    background-color: mix(white, $border-color, 50%);
    // border: none;
    // border-left: 2px solid $border-color;
    padding-left: 20px;

    @media (max-width: $screen-sm-max) {
      height: 52px;
    }

    .fa-search {
      position: absolute;
      font-size: 1.25em;
      pointer-events: none;
      z-index: 1;
    }

    &:focus-within {
      border-color: $brand-primary;
      background-color: white;
    }
  }

  .beer-finder-geo {
    margin-top: 15px;

    @media ( max-width: $screen-sm-max ) {
      width: 100%;
    }

    @media ( min-width: $screen-md-min ) {
      margin-left: 30px;
      margin-top: 0;
    }
  }
}

.beer-finder-search-input {
  flex: 1 1 auto;
  border: none;
  box-shadow: none;
  // margin-left: 12px;
  font-size: 18px;
  font-family: $font-family-sans-serif;
  padding: 8px 0 8px 32px;
  background: none;

  @media (max-width: $screen-sm-max) {
    width: 30px;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.beer-finder-search-clear {
  width: 40px;
  height: 40px;
  border-radius: 20px;

  &:focus {
    border: none;
    background-color:rgba($border-color, 0.25);
  }
}


.beer-finder-filters-controls {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-left: auto;

  @media ( min-width: $screen-md-min ) {
    display: none;
    margin-left: 0;
  }

  .btn-link {
    border-radius: 25px;
    height: 50px;
    width: 50px;
    display: block;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

    @media ( max-width: $screen-sm-max ) {
      margin-left: 5px;
      width: 40px;
      height: 40px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .beer-finder-search-toggle {
    background-color: mix(white, $border-color, 50%);
  }
}

.beer-finder-main {
  position: relative;

  // padding-top: $bf-sticky-height;
  // @media ( max-width: $screen-sm-max ) {
  //   margin-top: $bf-sticky-height-xs;
  // }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: rgba($color-mid-grey, 0.8);
    background-color: rgba($color-white, 0.8);
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, transform 0s linear 0.3s;
    transform: translateX(-100vw);

    .loading-overlay-message {
      font-family: $font-family-sans-serif;
      animation: loadingMessage 1s infinite;
    }
  }

  &.loading {
    .loading-overlay {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      transform: translateZ(0);
    }
  }
}

.beer-finder-main-inner {
  display: flex;

  @media ( max-width: $screen-sm-max ) {
    position: relative;
  }

  .beer-places {
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 33.3333%;

    @media ( max-width: $screen-sm-max ) {
      max-width: 100%;
      transition: transform 0.3s ease-in-out;
    }
  }

  .beer-finder-map {
    height: calc(100vh - #{$bf-sticky-height-xs});
    width: 100%;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 66.6667%;
    position: sticky;
    top: $bf-sticky-height;

    @media ( min-width: $screen-md-min ) {
      // width: 66.666666667%;
      height: calc(100vh - #{$bf-sticky-height});
    }

    .google-map {
      height: 100% !important;
    }

    @media ( max-width: $screen-sm-max ) {
      position: fixed;
      // left: 100%;
      top: $bf-sticky-height-xs;
      bottom: 0;
      max-width: 100%;
      height: auto !important;
      bottom: 0;
      transform: translateX(100%);
      z-index: 100;
      transition: transform 0.3s ease-in-out;
    }
  }

  .main.show-map & {
    //... move beer places
    .beer-places {
      @media ( max-width: $screen-sm-max ) {
        transform: translateX(-100%);
      }
    }

    // move beer finder map
    .beer-finder-map {
      @media ( max-width: $screen-sm-max ) {
        transform: translateX(0);
      }
    }
  }
}

.results-count {
  padding: 10px 15px;
  font-family: $font-family-serif;
  font-size: 25px;
  color: $color-mid-grey;
}

// List header spacing
$list-header-line-padding-x: 15px;
$list-header-line-padding-y: 20px;
$list-header-line-height: 30px;
$list-header-border-top-width: 1px;
$list-header-border-bottom-width: 1px;

// Calculate height for scroll offset
$list-header-height: ($list-header-line-padding-y * 2) + $list-header-line-height + ($list-header-border-top-width + $list-header-border-bottom-width);

.beer-places.panel-group {
  position: relative;
}

.beer-finder-list-header {
  position: sticky;
  top: $bf-sticky-height-xs;
  background-color: #fff;
  line-height: $list-header-line-height;
  font-weight: 600;
  font-size: 25px;
  transform: translateZ(0);
  z-index: 1;

  @media ( min-width: $screen-md-min ) {
    top: $bf-sticky-height;
  }

  .panel-title {
    margin-bottom: 0;
    border-bottom: $list-header-border-bottom-width solid $border-color;

    .marker-icon {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      padding: 3px;
      background-color: white;

      .icon {
        // fill: white;
        // fill: $color-black;
      }
    }

    .results-count {
      font-weight: normal;
      font-size: 1em;
      padding: 0;
      margin-left: 10px;
      padding-top: 4px;
      border-radius: 25px;
      padding: 7px 10px 4px 10px;
      color: white;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: $list-header-line-padding-y $list-header-line-padding-x;
    font-weight: bold;
    color: $text-color;

    .fa-angle-down {
      margin-left: auto;
      margin-right: 10px;
      transition: transform 0.2s ease-in-out;
      transform: rotate(-180deg);
    }
  }

  a.collapsed {
    .fa-angle-down {
      transform: rotate(0);
    }
  }
}

.beer-finder-list {
  scroll-margin-top: 72px + $list-header-height;

  .list {
    margin-bottom: 0;
    border-bottom: $list-header-border-bottom-width solid $border-color;
  }

  .title {
    margin-top: 0;
    // font-size: 25px;
    font-family: $font-family-sans-serif;
    font-weight: 600;

    a {
      color: $link-active-color;

      &:hover,
      &:focus {
        color:  $link-color;
      }
    }
  }

  .beer-place {
    position: relative;

    .beer-place-button {
      padding: 10px 35px 10px 15px;
      cursor: pointer;
      display: block;
      background: none;
      border: none;
      width: 100%;
      text-align: left;

      span {
        display: block;
      }
    }

    &::after {
      content: "\f054";
      position: absolute;
      font: normal normal normal 14px/1  "Font Awesome 5 Brands";
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      color: $link-color;
    }

    &:hover,
    &:focus-within,
    &.active {
      background-color: rgba($border-color, 0.25);
    }

    &:hover,
    &:focus-within {
      &::after {
        opacity: 1;
      }
    }
  }

  .no-results {
    padding: $list-header-line-padding-y $list-header-line-padding-x;
  }
}

.beer-finder-filters {
  .form-group {
    margin-bottom: 0;
  }

  @media ( max-width: $screen-sm-max ) {
    position: fixed;
    // top: 0;
    top: $bf-sticky-height-xs;
    // margin-top: $bf-sticky-height;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-y: scroll;
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;

    .form-group {
      margin-bottom: 12px;
    }

    // &.menu-in {
    //   transform: translateX(0);
    // }

    .main.show-filters & {
      transform: translateX(0);
    }
  }

  .view-map {
    // left: 30px;
    // right: 30px;
    width: auto;
  }
}

.beer-finder-filters-inner {
  display: flex;
  flex-wrap: nowrap;

  @media ( max-width: $screen-sm-max ) {
    padding: 15px;
    flex-wrap: wrap;

    > * {
      display: block;
      width: 100%;
      margin-bottom: 15px;
    }

    > .form-group {
      > .styled-dropdown {
        margin-right: 0;
      }
    }
  }

  > .btn {
    @media ( min-width: $screen-md-min ) {
      margin-right: 15px;
    }
  }
}

.beer-finder-toggle-map {
  .map-shown {
    // Hide Map/View List
    display: none;
  }

  .map-hidden {
    // View Map
    display: block;
  }

  .main.show-map & {
    .map-shown {
      // Hide Map/View List
      display: block;
    }

    .map-hidden {
      // View Map
      display: none;
    }
  }
}

.beer-finder-apply-filters,
.beer-finder-toggle-map {
  @media ( min-width: $screen-md-min ) {
    display: none;
  }
}
