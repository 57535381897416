.content-info {
  z-index: 20;
  position: relative;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: $footer-bg;
  color: $footer-text-color;
  h5 {
    margin-top: 0;
    color: $footer-highlight;
  }
  hr {
    border-top-color: $color-dark-grey;
  }
  p {
    font-family: $font-family-sans-serif;
  }

  .primary-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .primary-logo {
    margin: 0 1rem;

    a {
      display: block;
    }
  }

  .bcat-pint {
    width: 62%;

    @media (max-width: $screen-xs-max) {
      width: 62%;
    }
  }

  .bccbg-logo {
    width: 100%;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  .snbc-logo {
    // margin-top: -75px;
    width: 62%;

    @media (max-width: $screen-xs-max) {
      width: 62%;
    }

    a {
      display: block;
    }
  }

  /*
    .snbc-logo {
      padding: 0 25%;
      @media ( min-width: $screen-sm-min ) {
        padding: 60px 25px;
        padding: 14% 15%;
      }
    }
    .dmo-logos {
      padding: 15px 0;
      @media ( min-width: $screen-sm-min ) {
        padding: 0;
      }
      .dmo-logo {
        padding: 0 15px;
        width: 33.333%;
        @media ( min-width: $screen-sm-min ) {
          padding: 5px 50px;
          padding: 0% 5%;
          width: 25%;
        }
      }
    }
  */
  .primary-nav,
  .secondary-nav {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
    .menu-item {
      margin-right: 10px;
      @media (max-width: $screen-xs-max) {
        display: block;
        margin-right: 0;
        margin-bottom: 3px;
        text-align: center;
      }
    }
  }
  .primary-nav {
    font-size: 20px;
  }
  .list-social {
    font-size: 20px;
    line-height: 0;
    a {
      color: $link-color;
    }
    .social-email {
      display: inline-block;
    }
  }
}

.partner-logos {
  margin-bottom: 20px;
  @media (min-width: $screen-sm-min) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.copyright-info,
.snbc-info {
  display: block;
}

.copyright-info {
  color: $footer-highlight;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 30px;
  @media (min-width: $screen-sm-min) {
    margin-top: 0;
    font-size: 15px;
  }
}

.snbc-info {

}

#scrollTop {
  margin-left: auto;
  display: block;
  padding: 0;
  border: none;
  width: 30px;
}

.up-arrow {
  font-size: 45px;
}
