// Global Overwrites
// ====================================

body {
  font-family: $font-family-sans-serif;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.row-eq-height {
  @media (min-width: $screen-sm-min) {
    display: flex;
  }

  &::before,
  &::after {
    content: none;
  }
}

.row-eq-height-md {
  @media (min-width: $screen-md-min) {
    display: flex;
  }

  &::before,
  &::after {
    content: none;
  }
}

.align-items-center {
  @media (min-width: $screen-sm-min) {
    align-items: center;
  }
}

// Typography
p {
  font-size: 14px;

  @media (min-width: $screen-sm-min) {
    font-size: 15px;
  }

  line-height: 1.66666667;
  font-family: $font-family-serif;
}

.intro {
  font-size: 16px;
}

.title {
  font-size: 20px;
}

.title,
.intro {
  font-family: $font-family-serif;
  color: $title-color;
  font-weight: 400;
  line-height: 1.5;

  @media (min-width: $screen-sm-min) {
    font-size: 20px;
    line-height: 1.4;
  }
}

.lead {
  color: $color-light-green;
  font-family: $font-family-sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  @media (min-width: $screen-sm-min) {
    font-size: 25px;
    line-height: 35px;
  }
}

a {
  transition: color 0.2s cubic-bezier($cubic-bezier);

  &:hover,
  &:focus,
  &:hover &:focus {
    text-decoration: none;
  }
}

// Stretched links
.card {
  position: relative;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

hr {
  border-top-width: 2px;
}

@for $i from 1 through 6 {

  h#{$i},
  .h#{$i} {
    &:first-child {
      margin-top: 0;
    }
  }
}

h1 {
  font-family: $font-family-brand;
  font-weight: normal;
  font-size: 25px;
  line-height: 1.333;
  margin-bottom: 20px;

  @media (min-width: $screen-sm-min) {
    font-size: 45px;
  }
}

h2 {
  color: $color-mid-grey;
  margin-bottom: 20px;
  font-size: 25px;

  @media (min-width: $screen-sm-min) {
    font-size: 30px;
  }
}

h3 {
  font-weight: 600;
}

h5 {
  font-size: 15px;
  line-height: 20px;
}

h4,
.h4 {
  text-transform: none;
  color: $color-brown;
  // @media ( min-width: $screen-sm-min ) {
  //   margin-bottom: 20px;
  // }
}

.heading {
  font-size: 15px;
  line-height: 20px;
  text-transform: uppercase;
  color: $color-brown;
  letter-spacing: 0.025em;
}

// Sticky
.sticky {
  position: sticky !important;
  top: 48px !important;

  @media (min-width: $screen-sm-min) {
    top: 68px !important;
  }

  .admin-bar & {
    @media (min-width: $screen-sm-min) {
      top: 68px + 32px !important;
    }
  }
}

// Modals
.close {
  font-family: $font-family-brand-sc;
}

.modal-content {
  border-radius: 0;
  border: none;
}

.modal-video,
.gallery-modal {
  .close {
    color: white;
    position: fixed;
    top: 30px;
    right: 15px;
    text-shadow: none;
    opacity: 1;
    //padding-left: 15px;
    //padding-right: 15px;
    z-index: 10;

    span {
      // fix jagged focus outline
      display: block;
      padding: 15px;
    }
  }
}

.modal-video {
  .modal-dialog {
    margin: 0;
    width: 95%;

    top: 50%;
    left: 50%;
    position: absolute;

    &.modal-lg {
      @media (min-width: $screen-lg-min) {
        width: 75%;
      }
    }
  }

  &.fade .modal-dialog {
    transform: translate(-50%, -25%);
  }

  &.in .modal-dialog {
    transform: translate(-50%, -50%);
  }

  .modal-content {
    background-color: transparent;
    border: none;

    .modal-body {
      padding: 0;
      border-bottom: none;
    }
  }
}

.ui-datepicker-title select {
  color: $text-color;
}

// Check for missing alt tags
// img[alt=""],
// img:not([alt]) {
//   border: 5px dashed red;
// }

// Loading Spinner
.loading-spinner {
  content: "";
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: $brand-primary;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

// Global Classes
// ====================================

.bg-light {
  // background-color: mix($color-off-white, white, 50%);
  background-color: $color-off-white !important;
}

.fill-bronze {
  fill: $color-bronze;
}

.fill-brown {
  fill: $color-brown;
}

.list-social {
  @extend .list-inline;

  font-size: 20px;

  @media (min-width: $screen-md-min) {
    font-size: 22px;
  }

  .social-untappd {
    .icon {
      width: 1em;
      height: 1em;
      vertical-align: middle;
      display: inline-block;
      position: relative;
      top: -0.1em;
    }
  }

  .social-email {
    display: none;
  }

  a {
    display: block;
    padding: 0 5px;
    color: $link-active-color;

    .icon {
      fill: $link-active-color;
    }

    &:hover,
    &:focus {
      color: $link-color;

      .icon {
        fill: $link-color;
      }
    }
  }

  &.list-social-round > li {
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0;

    @media (min-width: $screen-md-min) {
      width: 50px;
      height: 50px;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-top: 6px;
      padding-bottom: 6px;

      @media (min-width: $screen-md-min) {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }
}

.icon-group {
  display: inline-block;
  white-space: nowrap;
  margin-right: 12px;
  margin-top: 10px;

  svg {
    vertical-align: middle;
    fill: $color-dark-grey;
  }

  span {
    font-weight: bold;
    font-size: 1.4rem;
    vertical-align: middle;
    color: $color-black;
  }
}

.link-all {
  font-weight: bold;
  text-decoration: none;
}

.link-white {
  color: white;

  &:hover,
  &:focus {
    color: $color-light-grey;
  }
}

.overlay {
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color-black, 0.98);
  color: $color-white;

  &.overlay-hidden {
    display: none;
  }

  .single-header &,
  article & {
    position: absolute;
    z-index: 5;
    background-color: rgba($color-black, 0.25);
  }
}

.overlay-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: $screen-md-min) {
    padding: 30px;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 1360px;
    padding: 40px;
  }
}

.overlay-content {
  h2 {
    color: $color-light-grey;
  }

  p {
    color: $color-white;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a:last-child {
    margin-top: 20px;
  }
}

.fa-external-link {
  font-size: 67%;
}

.smcp {
  font-feature-settings: "smcp";
}

.alert {
  border-radius: 0;
  text-align: center;
}

.searchable {
  display: none;
}

.control-label,
label {
  font-family: $font-family-sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.styled-dropdown {
  position: relative;
  margin-right: 15px;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    border-width: 2px;
    border-radius: 0px;
    box-shadow: none;
    background-color: transparent;
    background: none;
    outline: 0;
    border: none;
    position: relative;
    z-index: 10;
    font-weight: normal;
    color: $title-color;
    font-size: 15px;
    padding: 0 15px;
    padding-right: 3em;
    height: 45px;

    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    border-radius: 50px;
    border: 2px solid $border-color;
    //pointer-events: none;
    z-index: 5;
    transition: all 300ms ease;
  }

  &::before {
    content: "\f107";
    display: inline-block;
    font: normal normal 900 14px/0.7 "Font Awesome 5 Free";
    font-size: 2em;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 12px;
    right: 15px;
    color: $link-color;
    z-index: 10;
  }

  &:focus {
    &::after {
      border-color: $input-border-focus;
    }
  }

  &.active {
    select {
      color: $color-white;
    }

    &::after {
      background-color: $link-color;
      border-color: $link-color;
    }

    &::before {
      color: $color-white;
    }
  }
}

.styled-search {
  position: relative;
  margin-right: 15px;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none;
    }

    border-width: 2px;
    border-radius: 0px;
    box-shadow: none;
    background-color: transparent !important;
    background: none;
    outline: 0;
    border: none;
    position: relative;
    z-index: 10;
    font-weight: normal;
    color: $title-color;
    font-size: 15px;
    padding: 0 15px;
    padding-right: 3em;
    height: 45px;

    &:hover,
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    border-radius: 50px;
    border: 2px solid $border-color;
    //pointer-events: none;
    z-index: 5;
    transition: all 300ms ease;
  }

  &:focus {
    &::after {
      border-color: $input-border-focus;
    }
  }

  &.active {
    select {
      color: $color-white;
    }

    &::after {
      background-color: $link-color;
      border-color: $link-color;
    }

    &::before {
      color: $color-white;
    }
  }
}

.comment-form input[type="email"],
.comment-form input[type="text"],
.comment-form input[type="url"],
.comment-form textarea,
.form-control,
.search-form .search-field {
  border-width: 2px;
  border-radius: 0;
  box-shadow: none;
}

.bullet {
  color: $text-color;
}

/*
.row-match-height {
  display: flex;
}
*/

// Sections <section>
// ====================================
.section {
  scroll-margin-top: 66px;
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: $screen-sm-min) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &.section-large {
    padding-top: 85px;
    padding-bottom: 85px;

    @media (min-width: $screen-sm-min) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  &.section-no-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  .section {
    margin-left: 0;
    margin-right: 0;
  }

  // &[class*="bg-"] {}
}

.section-band {
  padding-top: 30px;
  padding-bottom: 30px;
  @include img-retina("../images/bg-dots.png", "../images/bg-dots@2x.png", 600px, 600px);

  @media (min-width: $screen-sm-min) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.section-friends {
  border-top: 1px solid $border-color;
}

.padding {
  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: $screen-sm-min) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  &-small {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &-large {
    padding-top: 85px;
    padding-bottom: 85px;

    @media (min-width: $screen-sm-min) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
}

.padding-top {
  padding-top: 60px;
  padding-bottom: 0;

  @media (min-width: $screen-sm-min) {
    padding-top: 75px;
  }

  &-small {
    padding-top: 30px;

    @media (min-width: $screen-sm-min) {
      padding-top: 30px;
    }
  }

  &-large {
    padding-top: 85px;

    @media (min-width: $screen-sm-min) {
      padding-top: 100px;
    }
  }
}

.padding-bottom {
  padding-top: 0;
  padding-bottom: 60px;

  @media (min-width: $screen-sm-min) {
    padding-bottom: 75px;
  }

  &-small {
    padding-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 30px;
    }
  }

  &-large {
    padding-bottom: 85px;

    @media (min-width: $screen-sm-min) {
      padding-bottom: 100px;
    }
  }
}

.no-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.border-top {
  border-top: 1px solid $border-color;
}

.section-header {
  margin-bottom: 30px;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 70px;
  }

  h2 {
    margin-bottom: 10px;
    // font-size: 24px;
    font-family: $font-family-brand;
    font-weight: normal;
    color: $color-dark-grey;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.section-footer {
  margin-top: 30px;

  @media (min-width: $screen-sm-min) {
    margin-top: 70px;
  }
}

.section-ale-trails {
  text-align: center;

  p:last-child {
    margin-bottom: 0;
  }

  .btn:last-child {
    margin-top: 30px;
    margin-bottom: 0;
  }
}

.section-band {
  background-color: $brand-primary;
  color: $color-dark-green;

  .title {
    color: $color-dark-green;
    //padding: 10px 0;
  }

  &.section-band-events {
    background-color: $color-light-grey;
    color: $color-brown;

    .title {
      color: $color-brown;
    }
  }
}

.section-events {
  background-color: $color-brown;
}

.section-recent-posts {
  .entry-title {
    font-size: 21px;
  }

  .post {
    footer {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $border-color;
    }

    @media (min-width: $screen-sm-min) {
      border-right: 1px solid $border-color;

      footer {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;

      footer {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
    }

    header {
      margin-bottom: 20px;
    }

    .updated {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .entry-tags {
      margin-top: 20px;
    }
  }
}

.section-intro {
  .lead {
    text-align: center;
    margin-bottom: 60px;
  }
}

// Mailchiump

.mailchimp-form {
  font-size: 18px;

  @media (min-width: $screen-sm-min) {
    font-size: 24px;
  }

  .email {
    display: inline-block;
    padding: $padding-large-vertical $padding-large-horizontal;
    font-size: $font-size-large;
    line-height: $line-height-large;
    border: 2px solid white;
    background-color: white;
    vertical-align: middle;
    color: $text-color;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .mc-field-group {
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .email::-webkit-input-placeholder {
    color: $color-mid-grey;
  }

  input:focus {
    outline: none;
  }

  .btn-default {
    display: inline-block;
  }

  .btn {
    @media (max-width: $screen-sm-max) {
      width: 100%;
      display: block;
    }
  }
}

// Polls
// ==================================

.section-poll {
  background-position: bottom right;
  background-repeat: no-repeat;
  @include img-retina("../images/illustration-beers.jpg", "../images/illustration-beers@2x.jpg", 1073px, 630px);
}

.poll-container {
  background-color: $color-white;
  padding: 30px;
  font-size: 15px !important;

  .totalpoll-question-content {
    text-transform: none;
    font-weight: normal !important;
    font-family: $font-family-serif !important;
    color: $title-color;
    font-size: 20px !important;
    line-height: 30px;

    @media (min-width: $screen-sm-min) {
      font-size: 25px !important;
      line-height: 35px;
    }
  }

  .totalpoll-choice-separator {
    border-top: 2px solid $border-color !important;
  }

  .totalpoll-question-choices {
    border: 2px solid $border-color !important;
    border-bottom: none;

    &-item {
      @media (max-width: 991px) {
        width: 100%;
        margin-bottom: -5px !important;
      }

      &-label,
      &-label.checked {
        color: $title-color;
      }

      &-votes-bar {
        background: #eb5c25 !important;
      }
    }
  }

  .totalpoll-button {
    border-radius: 30px !important;
    border-width: 2px !important;
    font-weight: bold !important;
    font-size: 15px !important;
    color: $link-color !important;
  }
}

// CTA
// ==================================

.cta-wrap {
  @media (min-width: $screen-sm-min) {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }
}

.cta-description,
.cta-action {
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: $screen-sm-min) {
    //width: 50%;
    //float: left;
    display: table-cell;
    vertical-align: middle;
    margin-bottom: 0;
  }

  .cta-dmo & {
    text-align: center;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 30px;
      display: block;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: $screen-md-min) {
      //width: 50%;
      //float: left;
      display: table-cell;
      vertical-align: middle;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 20px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .title {
      margin-bottom: 10px;
    }

    &:last-child .btn {
      margin-bottom: 0;
    }
  }
}

.cta-description {
  padding: 10px 0;

  @media (min-width: $screen-sm-min) {
    text-align: left;
  }

  .title {
    @media (min-width: $screen-sm-min) {
      font-size: 25px;
    }
  }

  .title,
  p {
    padding-top: 1px;
    padding-bottom: 1px;
    margin: 0;
  }
}

.cta-action {
  @media (max-width: $screen-xs-max) {
    .btn {
      margin-bottom: 20px;
    }
  }

  @media (min-width: $screen-sm-min) {
    text-align: right;

    .list-social {
      text-align: right;
      margin-bottom: 0;
      padding: 5px 0;
    }
  }
}

// Content/Sponsored
// ===================================
.content-padding {
  padding-top: 35px;
  padding-bottom: 60px;
}

.content-type {
  margin-bottom: 4px;
  color: rgba($color-mid-grey, 0.5);
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.025em;
}

.content-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 50px;
}

.content-over-photo {
  background-color: $color-white;
  padding: 20px;

  @media (min-width: $screen-sm-min) {
    padding: 30px;
    min-height: 425px;
  }
}

.content-friends {
  @media (max-width: $screen-xs-max) {
    padding: 10px 0 0 0 !important;
    margin-left: -15px !important;
    margin-right: -15px !important;

    .content-bg {
      background-position: center top;
      background-size: contain;
      background-color: $color-light-grey;
      padding: 45% 15px 15px 15px;
    }
  }

  .single-post & {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 30px !important;

    @media (min-width: $screen-sm-min) {
      margin-top: 30px !important;
      border-top: 2px solid $border-color;
      border-bottom: 2px solid $border-color;
    }
  }

  .content-over-photo {
    display: flex;
    flex-direction: column;
    min-height: 0;

    @media (min-width: $screen-sm-min) {
      min-height: 540px;
    }

    .content-body {
      flex: 1 1 auto;
    }
  }

  .lead {
    margin-top: 0;
    font-family: $font-family-serif;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25;
    color: $color-brown;

    @media (min-width: $screen-sm-min) {
      font-size: 24px;
    }
  }

  p {
    font-family: $font-family-sans-serif;

    &:last-child {
      margin-bottom: 30px;
    }
  }

  h2 {
    color: $color-light-green;
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 1.25;
    text-transform: uppercase;
    font-weight: bold;

    @media (min-width: $screen-sm-min) {
      font-size: 25px;
      margin-bottom: 20px;
    }
  }

  h1 {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    color: $color-brown;
    line-height: 1.25;
    font-size: 30px;
    margin-top: 0;

    @media (min-width: $screen-sm-min) {
      font-size: 35px;
    }
  }
}

// Homepage / Landing
// ===================================

#scrollPrompt {
  font-family: $font-family-sans-serif;
}

.has-fullscreen-landing {
  .banner-transparent {
    position: absolute;
    background-color: transparent;
    box-shadow: 0px 2px 0px rgba(#0b0201, 0);

    .bcat-logo {
      fill: $color-white;
    }

    .banner-bottom {
      border-top: 2px solid transparent;
    }

    .nav-primary .nav .menu-item a,
    .nav-connect .list-social a,
    .nav-hashtag a,
    .btn-circle {
      color: $color-white;

      &:hover {
        color: $link-color;
      }
    }

    .btn-circle {
      border-color: $color-white;
    }

    .toggle-menu .border,
    .toggle-menu .border:after,
    .toggle-menu .border:before {
      background: $color-white;

      &:hover {
        background: $link-color;
      }
    }

    &.sticky {
      background-color: $color-white;
      box-shadow: 0px 2px 0px rgba(#0b0201, 0.1);

      .banner-bottom {
        @media (min-width: $screen-sm-min) {
          border-top: 2px solid rgba(darken($border-color, 10%), 0.5);
        }
      }
    }

    &.menu-active {

      .nav-primary .nav .menu-item a,
      .nav-connect .list-social a,
      .nav-hashtag a,
      .btn-circle {
        color: $link-color;

        &:hover {
          color: $link-active-color;
        }
      }

      .btn-circle {
        border-color: $link-color;
      }

      .toggle-menu .border {
        background: transparent;
      }

      .toggle-menu .border:after,
      .toggle-menu .border:before {
        background: $link-color;

        &:hover {
          background: $link-active-color;
        }
      }
    }
  }
}

.fullscreen-landing {
  position: relative;
  overflow: hidden;
  height: 100vh;
  border-bottom: 2px solid white;

  .tagline,
  .header-container {
    z-index: 15;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }
  }
}

.tagline {
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 40px;

  @media (min-width: $screen-sm-min) {
    font-size: 48px;
    margin-bottom: 0.325em;
  }

  @media (min-width: $screen-md-min) {
    font-size: 56px;
  }

  // @media (min-width: $screen-lg-min ) and (min-height: 640px){
  //   font-size: 64px;
  // }
  span {
    display: block;
    line-height: 1;

    &.gf-sc {
      line-height: 1;
      margin-bottom: 0.1em;
    }
  }
}

.gf-sc {
  font-family: $font-family-brand-sc;
}

.gf-s {
  font-family: $font-family-brand;
}

// Ale Trails
// ===================================

// Filters
.aletrail-filter {
  padding-top: 25px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid $border-color;

  .row {
    display: flex;
    margin: 0;

    &::before,
    &::after {
      content: none;
    }

    @media (max-width: $screen-md-max) {
      flex-wrap: wrap;
      justify-content: center;

      .form-group {
        width: 100%;
      }
    }
  }

  .date-preset-ranges {
    display: none;

    @media (min-width: $screen-md-min) {
      display: block;
    }
  }
}

article {
  .lead {
    margin-bottom: 30px;
  }
}

.ale-trail-archive-list {
  margin: 0;
  padding: 0;

  ul,
  ol {
    margin-bottom: 1rem;
  }
}

.ale-trail-archive-item {
  position: relative;
  overflow: hidden;

  .col-xs-12 {
    z-index: 10;
  }

  @media (min-width: $screen-sm-min) {
    .overlay {
      transition: background-color 0.4s cubic-bezier($cubic-bezier);
      background-color: rgba($color-black, 0.8);

      &.hover {
        background-color: rgba($color-black, 0.25);
      }
    }
  }
}

.ale-trail-header {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-size: cover;
  background-position: center center;

  @media (min-width: $screen-sm-min) {
    padding: 0;
    min-height: 535px;
  }

  &.has-video {
    background-image: none !important;
  }

  .ale-trail-title,
  .ale-trail-subtitle {
    color: $color-white;
    font-weight: 100;
  }

  .ale-trail-header-container {
    z-index: 10;
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;

    @media (min-width: $screen-sm-min) {
      height: 535px;
      padding-right: 30px;
      padding-left: 30px;
      max-width: 800px;
      margin: 0 auto;
    }
  }

  .ale-trail-header-contents {
    display: table-cell;
    vertical-align: middle;
  }
}

.ale-trail-coming-soon {
  .teaser-video {
    display: none;
  }
}

.teaser-video {

  .ale-trail-header &,
  .next-ale-trail &,
  .frontpage-landing & {
    z-index: 1;
    position: absolute;
    width: auto;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    @media (min-width: 680px) {
      width: 100.5%;
      height: auto;
    }

    @media (min-width: $screen-sm-min) {
      width: auto;
      height: 100%;
    }

    @media (min-width: 1220px) {
      width: 100.5%;
      height: auto;
    }

    @supports (object-fit: cover) {
      width: 101%;
      height: 101%;
      object-fit: cover;
    }
  }
}

.ale-trail-content {
  background-color: $color-white;
  padding: 30px;
  min-height: 425px;

  @media (max-width: $screen-xs-max) {
    display: none;
  }

  p {
    margin-bottom: 20px;
  }
}

.ale-trail-title-container {
  display: flex;
  background-color: transparent;
  position: relative;
  text-align: center;
  width: 100%;

  @media (max-width: $screen-xs-max) {
    display: block;
    min-height: 0;
    padding: 0;
    //margin-bottom: 70px;
  }

  .icon {
    width: 50px;
    height: 50px;
    fill: $color-white;
    transition: fill 0.2s cubic-bezier($cubic-bezier);
  }

  a,
  .ale-trail-coming-soon {
    flex: 1;
    align-self: center;
    text-decoration: none;
    color: $color-white;
    transition:
      color 0.2s cubic-bezier($cubic-bezier),
      transform 0.2s cubic-bezier($cubic-bezier);

    @media (max-width: $screen-xs-max) {
      position: static;
      transform: none;
      //display: block;
    }

    &:hover {
      color: $color-white;
      //transform: translateY(-30px);
    }
  }
}

.ale-trail-title {
  font-family: $font-family-brand;
}

.ale-trail-subtitle,
.ale-trail-up-next,
.subtitle,
.bordered-title {
  font-family: $font-family-brand-sc;
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 100;
  font-size: 20px;
  line-height: 1;

  @media (min-width: $screen-sm-min) {
    font-size: 30px;
  }

  a {
    color: white;
  }
}

.ale-trail-archive-item .ale-trail-subtitle {
  margin-bottom: 0;

  @media (min-width: $screen-sm-min) {
    margin-bottom: 20px;
  }
}

.ale-trail-up-next,
.bordered-title {
  border: 2px solid $link-color;
  display: inline-block;
  margin: 0 auto;
  padding: 2px 7px;
  color: $link-color;
}

.ale-trail-archive-item {
  margin-bottom: 2px;
}

.ale-trail-meta {
  margin-bottom: 20px;
}

.ale-trail-meta-description {
  line-height: 25px;
  font-size: 15px;
}

.ale-trail-duration {
  // FIX ME
  display: none;
}

//Option A:
.option-a {
  .ale-trail-title {
    @extend h1;

    @media (min-width: $screen-sm-min) {
      font-size: 40px;
    }
  }
}

.itinerary {
  position: relative;
  @include clearfix();
}

.itinerary-column {
  position: relative;

  @media (min-width: $screen-md-min) {
    float: left;
    width: 50%;
  }

  &.map-column {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.4s cubic-bezier($cubic-bezier);
    transform: translateX(100%);
    z-index: 775;

    @media (min-width: $screen-md-min) {
      position: absolute;
      height: 100%;
      top: 0;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: none;
      transition: none;
      z-index: 10;
    }

    &.map-active {
      transform: none;
    }
  }
}

// .stuck {
//   position: fixed;
//   top: 68px;
//   z-index: 2;
// }

.itinerary-map {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $brand-primary;

  @media (min-width: $screen-md-min) {
    position: sticky;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    height: 100vh;
  }

  .acf-map {
    height: 100%;
    width: 100%;
  }

  .hide-map {
    font-family: $font-family-brand-sc;
  }
}

.acf-map {
  .marker {
    display: none;
  }

  .poi-marker {
    position: absolute;
    cursor: pointer;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px white;
    background-color: $color-orange;
    transition: background-color 0.2s linear;

    &::after {
      position: absolute;
      content: attr(data-alpha);
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 11px;
      line-height: 18px;
      font-family: $font-family-sans-serif;
      white-space: nowrap;
      color: white;
    }

    &:hover {
      background-color: mix($color-red, $color-orange, 50%);
    }

    &.poi-type-brewery {
      background-color: mix($color-yellow, $color-orange, 50%);

      &:hover {
        background-color: mix($color-yellow, $color-orange, 25%);
      }
    }

    .icon,
    img {
      display: none;
      // width: 100%;
      // height: 100%;
    }

    &:hover,
    &.active {
      z-index: 100 !important;
    }
  }
}

.marker-icon,
.marker-content {
  display: inline-block;
  vertical-align: middle;
}

.marker-icon {
  width: 48px;
  height: 48px;
  margin-right: 10px;

  .icon,
  > svg {
    width: 100%;
    height: 100%;
    fill: $logo-color;

    path[style*="fill"] {
      fill: $logo-color !important;
    }
  }
}

.marker-content {
  position: relative;
  max-width: 240px;
  font-family: $font-family-sans-serif;

  &.beer-place {
    max-width: none;
  }

  .poi-title {
    font-size: 17px;
    line-height: 1.125;
    //margin-bottom: 5px;
  }

  .marker-type {
    color: $logo-color;
    text-transform: uppercase;
    font-weight: bold;
  }

  a:focus {
    outline: thin dotted;
    outline-offset: -1px;
    text-decoration: underline;
  }
}

.view-map {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  border-radius: 30px;
  border: none;
  padding: 8px 22px;
  background-color: $color-brown;
  color: white;
  font-family: $font-family-brand-sc;
  font-size: 18px;

  &:hover,
  &:focus,
  &:active {
    color: white;
    background-color: $link-color;
  }

  .fa {
    position: relative;
    top: -3px;
    margin-right: 6px;
    font-size: 16px;
  }

  &.active {
    box-shadow: none !important;
    color: white !important;
    background-color: $link-color !important;

    &:hover,
    &:focus {
      color: white;
      background-color: $color-brown !important;
    }

    .fa {
      &::before {
        content: "";
      }
    }
  }
}

.itinerary-map-controls {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 30px;
  pointer-events: none;

  * {
    pointer-events: auto;
  }
}

.itinerary-day {
  &:last-child {
    border-bottom: 2px solid $border-color;
  }

  &.active {
    .daily-summary {
      opacity: 0;
      height: 0;
      margin: 0;
      transition: all 1s ease;
    }

    /* Rotate the arrow icon for the expanded day */
    .open-close-icon {
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  .itinerary-day-header {
    background-color: $brand-primary;
    padding: 10px 30px;
    border-bottom: 1px solid white;
    @include clearfix();

    h2 {
      margin-bottom: 0;
      color: $color-white;
    }

    .daily-summary {
      margin-top: 5px;
      /* font-weight: bold; */
      color: white;
      font-family: "PublicaSans";
      transition: all 1s ease;
    }

    /* Style the arrow icon */
    .open-close-icon {
      float: right;
      font-size: 34px;
      color: #fff;
      margin-top: -34px;
      cursor: pointer;
      position: relative;
      line-height: 1em;
    }
  }
}








.itinerary-travel-info,
.itinerary-suggestions {
  padding: 30px;
}

.itinerary-travel-info {
  border-bottom: 2px solid $border-color;
}

.itinerary-suggestions {
  border-top: 2px solid $border-color;
}

.itinerary-locations,
.itinerary-travel-info,
.itinerary-suggestions {
  border-left: 2px solid $border-color;
  border-right: 2px solid $border-color;

  @media (min-width: $screen-md-min) {
    border-right: none;
  }

  h3 {
    @extend .heading;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.itinerary-control {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: none;
  font-weight: 700;
  color: $color-mid-grey;
  text-transform: uppercase;

  @media (min-width: $screen-sm-min) {
    border-bottom: 2px solid $border-color;
  }
}

.itinerary-control-action {
  display: inline-block;
  margin-right: 30px;

  .fa {
    font-size: 150%;
    position: relative;
    top: 0.125em;
    margin-left: 10px;
  }
}

.itinerary-location {
  position: relative;
  padding: 30px;

  @media (min-width: $screen-sm-min) {
    padding-left: 105px;
  }

  &::after {
    // itinerary border
    display: none;
    z-index: 0;
    content: "";
    position: absolute;
    top: -15px;
    bottom: -15px;
    left: 32px;
    width: 2px;
    //border-left: 2px dotted rgba(white, 0.95);
    background-color: $border-color;

    @media (min-width: $screen-sm-min) {
      display: block;
      left: (60px - 3px + 1px); // subtract "border" width
      width: 3px;
      //border-left: 3px dotted rgba(white, 0.95);
    }
  }

  &:first-child::after {
    top: 40px;
  }

  &:last-child::after {
    bottom: 100%;
    height: 70px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus,
  &.active {
    background-color: rgba($border-color, 0.25);
  }

  &.location-travel {
    &:first-child::before {
      content: "";
      position: absolute;
      top: 30px;
      left: -8px;
      background-color: $border-color;
      width: 15px;
      height: 15px;
      border-radius: 50%;

      @media (min-width: $screen-sm-min) {
        left: 52px;
      }
    }

    &::after {
      background-color: transparent;
      border-left: 2px dotted $border-color;

      @media (min-width: $screen-sm-min) {
        border-left: 3px dotted $border-color;
      }
    }
  }

  .poi-icon {
    z-index: 1;
    position: absolute;
    top: 30px;
    left: -16px;

    @media (min-width: $screen-sm-min) {
      top: 30px;
      left: 30px;
    }

    &[data-alpha] {
      &::after {
        position: absolute;
        content: attr(data-alpha);
        top: 36px;
        left: 6px;
        border-radius: 18px;
        height: 18px;
        min-width: 18px;
        text-align: center;
        font-size: 11px;
        //font-weight: bold;
        line-height: 18px;
        white-space: nowrap;
        color: white;
        background-color: $color-orange;

        @media (min-width: $screen-sm-min) {
          top: 0;
          left: 0;
        }
      }
    }
  }

  &.location-brewery {
    .poi-icon::after {
      background-color: mix($color-yellow, $color-orange, 50%);
    }
  }
}



.poi-icon {
  width: 30px;
  height: 30px;
  padding: 5px;
  background-color: $border-color;
  border-radius: 50%;

  @media (min-width: $screen-sm-min) {
    width: 60px;
    height: 60px;
    padding: 10px;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: $logo-color;

    > path[style^="fill"] {
      fill: $logo-color !important;
    }
  }

  /*
  &.interactive {
    // these ones are buttons
    border: none;
    box-shadow: none;

    svg, & {
      transition: fill 0.3s ease-in-out;
    }

    &:hover,
    &:focus {
      background-color: $logo-color;
      svg {
        fill: $border-color;
      }
    }
  }
*/
}

.poi-title {
  font-size: 17px;
  font-weight: 700;
  color: $color-brown;
  margin-bottom: 2px;
  line-height: 1.1111111;

  @media (min-width: $screen-sm-min) {
    font-size: 20px;
  }

  .poi-day-label {
    background-color: #73c2ac;
    color: white;
    padding: 3px 5px;
    border-radius: 2px;
    line-height: 1;
    font-size: 12px;
  }
}

.poi-location {
  font-size: 12px;
  text-transform: uppercase;
  color: $color-dark-grey;
  // font-weight: 700;
}

.poi-contact-info {
  margin: 6px 0;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    &::after {
      content: "\02022";
      margin-left: 5px;
    }

    &:last-child {
      &::after {
        content: "";
        margin-left: 0;
      }

      &:empty {
        display: none;
      }
    }
  }
}

.poi-description {
  margin-top: 5px;
}

.poi-description,
.itinerary-travel-info p {
  font-style: italic;
}

// DMO Stuff
.section-dmos {
  position: relative;
  z-index: 20;
}

.dmo-title {
  @extend h2;
  color: $logo-color;
}

.dmo-intro {
  @extend h4;
  color: $color-light-green;
}

.dmo-logos {
  @include clearfix();
  padding-top: 15px;
  padding-bottom: 15px;

  .dmo-logo {
    display: inline-block;
    // float: left;
    vertical-align: middle;
    width: 49%;
    padding: 15px 30px;
  }

  &.logo-count-3,
  &.logo-count-5,
  &.logo-count-7 {
    .dmo-logo {
      width: 32.3333%;
      padding: 15px;
    }
  }
}

.dmo-link {
  font-weight: bold;

  &::after {
    content: "\02192";
    color: $link-color;
  }
}

.next-ale-trail {
  .ale-trail-archive-item {
    border-bottom: none;
    margin-bottom: 0;
  }

  .icon-container {
    margin-bottom: 15px;
  }
}

// Road Trip Journals (tasting-notes)
// ===================================

// Map tweaks
.tasting-notes,
#region-map {
  .acf-map {
    .poi-marker {
      height: 24px;
      width: 24px;

      .icon,
      img {
        display: block;
        // width: 100%;
        // height: 100%;
        // transform: rotate(45deg);
        // fill: $color-brown;
        // Small Icons:
        width: 60%;
        margin-left: 20%;
        height: 100%;
        fill: white;
      }
    }
  }
}

// Nav Container
.article-header {
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 2px solid $border-color;
  background-color: white;

  @media (max-width: $screen-sm-max) {
    text-align: center;
  }

  &.pinned {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    margin: 0;
  }
}

// Section Navigation
.nav-anchor {
  min-width: 50%;
  display: inline-block;
  position: relative;

  @media (max-width: $screen-sm-max) {
    display: block;
  }
}

.nav-anchor-toggle {
  display: block;
  padding: 10px 20px;
  width: 100%;
  background-color: white;
  border: none;
  border-bottom: 1px solid $border-color;
  font-weight: bold;
  color: $link-color;

  @media (min-width: $screen-md-min) {
    padding: 20px;
    border: none;
    border-right: 1px solid $border-color;
  }
}

.nav-anchor-menu {
  position: absolute;
  z-index: 100;
  margin-bottom: 0;

  overflow: hidden;
  text-align: left;
  background-color: $brand-primary;
  width: 100%;
  @include list-unstyled;

  .nav-anchor-link {
    color: white;
    font-weight: bold;
    padding: 1rem 1.25rem;

    &:hover,
    &:focus {
      background-color: $link-color;
    }
  }

  // Dropdown Styling
  border-top: none;
  max-height: 0;
  transition: max-height 275ms ease-out;

  &.expanded {
    border-top: 0px solid rgba(white, 0.17);
    max-height: 400px;
  }

  .nav-anchor-item {
    display: block;
    border-top: 1px solid rgba(white, 0.17);

    &:first-child {
      border-top: none;
    }

    a {
      display: block;
    }
  }
}

.tasting-notes-days-nav {
  .itin-day {
    display: inline-block;
    border-right: 1px solid rgba(white, 0.17);
    font-weight: normal;
    padding-right: 10px;
    margin-right: 10px;
  }
}

// Back to Site Header
.tasting-notes-banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media (min-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .tasting-notes-title {
    margin-top: 10px;
    display: block;
    line-height: 1;
    font-size: 20px;
    font-family: $font-family-brand-sc;
    font-weight: normal;
    color: white;
    white-space: nowrap;
  }

  .nav,
  .nav-brand,
  .nav-connect {

    //padding-left: 15px;
    //padding-right: 15px;
    @media (min-width: $screen-sm-min) {
      flex: 1;
      width: 50%;
      max-width: 50%;
    }
  }

  .nav-brand {

    /*
      .brand,
      .bcat-logo {
        width: 120px;
        height: 40px;
      }
    */
    @media (max-width: $screen-xs-max) {
      position: absolute;
      top: 50px;
      width: 100%;
    }

    .brand {
      position: relative;
      width: 100%;
      // height: auto;
    }

    .bcat-logo {
      transition: all 0.2s ease-in-out;
      opacity: 1;
    }

    .tasting-notes-banner-back {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      transition: all 0.2s ease-in-out;
      opacity: 0;
    }

    &:hover {
      .tasting-notes-banner-back {
        opacity: 1;
        color: white;
      }

      .bcat-logo {
        opacity: 0;
      }
    }
  }

  .nav-connect,
  .nav {
    @media (max-width: $screen-xs-max) {
      position: absolute;
      top: 0;
    }
  }

  .nav {
    left: 0;
  }

  .nav-connect {
    right: 0;

    .list-social {
      font-size: 18px;
    }
  }

  .nav a,
  .tasting-notes-banner-back {
    display: inline-block;
    padding: 15px;
    font-size: 18px;
    font-family: $font-family-brand-sc;
    font-weight: normal;
    color: white;

    &:hover,
    &:focus {
      color: $link-color;
    }

    .arrow {
      font-family: $font-family-sans-serif;
      font-weight: bold;
      vertical-align: top;
    }
  }
}

.header-title {
  @extend .ale-trail-title;
}

.header-subtitle {
  @extend .ale-trail-subtitle;
}

.header-container {
  position: relative;
  z-index: 20;
}

.open-gallery {
  position: relative;
  // background-color: $text-color;
  display: block;

  img {
    transition: opacity 0.2s cubic-bezier($cubic-bezier);
    transform: translateZ(0);
  }

  .fa {
    display: inline-block;
    background-color: $text-color;
    padding: 4px 4px 2px 4px;
    transition: color 0.2s cubic-bezier($cubic-bezier);
    position: absolute;
    top: 15px;
    right: 15px;
    color: white;
    font-size: 14px;
  }

  &:hover {
    img {
      opacity: 0.925;
    }

    .fa {
      color: $color-bronze;
    }
  }
}

.lightbox-prompt {
  vertical-align: middle;
  font-style: italic;
  color: $color-light-green;
  font-size: 10px;
  display: none;

  @media (min-width: $screen-sm-min) {
    //display: inline-block;
    float: right;
    font-size: 14px;
    padding-top: 5px;
    display: block;
  }
}

.info-photo-details {
  padding-top: 5px;

  @media (min-width: $screen-sm-min) {
    padding-top: 10px;
  }

  .photo-poi-icon {
    margin-right: 9px;

    @media (max-width: $screen-xs-max) {
      display: inline-block !important;
      margin-right: 5px;
    }
  }

  .photo-poi-icon,
  .photo-caption {
    display: inline;
    vertical-align: middle;

    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }

  .photo-caption {
    font-size: 14px;
    //padding-left: 9px;
    color: #929495;
  }
}

.photo-poi-icon {
  width: 15px;
  height: 15px;

  @media (min-width: $screen-sm-min) {
    width: 30px;
    height: 30px;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: $logo-color;
  }
}

.single-tasting-notes {
  .section-band {
    position: relative;
    z-index: 15;
  }
}

// Feature Header

.feature-header {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 18%;

  .feature-type {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 50%;
    margin: 0 auto;
    padding: 30px;
    z-index: 10;
  }

  .overlay {
    z-index: 0;
    pointer-events: none;
  }

  a {
    display: block;

    &:hover {
      color: $color-brown;
    }
  }

  .header-title {
    margin-top: 20px;
  }

  @media (min-width: $screen-sm-min) {
    .bordered-title {
      font-size: 20px;
    }

    .header-title {
      font-size: 35px;
      margin-top: 0.25em;
      margin-bottom: 0.25em;
      line-height: 1.4;
    }

    .header-subtitle {
      font-size: 20px;
    }
  }
}

// Author Profile
.author-profile {
  @include clearfix();
}

.author-profile-image {
  float: left;
  width: 32px;
  height: 32px;
  margin-right: 15px;

  // Make it a circle
  border-radius: 50%;
  overflow: hidden;

  @media (min-width: $screen-sm-min) {
    width: 128px;
    height: 128px;
  }

  &-small {
    @media (min-width: $screen-sm-min) {
      width: 32px;
      height: 32px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.author-profile-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.author-profile-social {
  > a {
    display: inline-block;
    width: 2em;
    height: 2em;
    background-color: white;
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;

    span {
      position: relative;
      top: 2px;
      vertical-align: middle;
    }
  }
}

.map-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid $border-color;
  background-color: white;
  z-index: 10;
  padding: 10px;

  @media (max-width: $screen-xs-max) {
    transition: 200ms cubic-bezier($cubic-bezier);
    transform: translateY(-100%);

    .map-active & {
      position: fixed;
      transform: none;
    }
  }

  .hide-map {
    font-weight: bold;
    float: right;
  }
}

.ale-trail-link {
  display: inline-block;
  padding: 5px 10px;
  // font-weight: bold;

  @media (max-width: $screen-xs-max) {
    display: none;
  }

  a {
    text-decoration: underline;
  }

  .icon-container {
    display: inline-block;
    vertical-align: middle;
    height: 30px;
    margin-bottom: -3px;
    margin-top: -2px;

    svg {
      fill: $brand-primary;
    }
  }
}

// Flickity Carousel
// ================================

.logo-carousel {
  overflow: hidden;
  height: 140px;
  box-sizing: content-box;
  position: relative;

  &.flickity-enabled {

    //position: relative;
    .carousel-cell {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 15%;
    z-index: 1;
    pointer-events: none;
  }

  &::before {
    background-image: linear-gradient(to right, rgba($color-black, 1) 0%, rgba($color-black, 0) 100%);
    left: 0;
  }

  &::after {
    background-image: linear-gradient(to left, rgba($color-black, 1) 0%, rgba($color-black, 0) 100%);
    right: 0;
  }

  .flickity-prev-next-button {
    z-index: 5;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }

  .carousel-cell {
    width: 140px;
    margin: 0 35px;
  }
}

.flickity-prev-next-button {
  background-color: $link-color;
  width: 30px;
  height: 30px;

  @media (min-width: $screen-md-min) {
    width: 40px;
    height: 40px;
  }

  &:hover {
    background-color: $color-white;

    .arrow {
      fill: $link-color;
    }

    .no-svg {
      color: $link-color;
    }
  }

  svg {
    left: 25%;
    top: 25%;
    width: 50%;
    height: 50%;
  }

  .arrow {
    fill: $color-white;
  }

  .no-svg {
    color: $color-white;
  }

  &.previous {
    left: 20px;

    @media (min-width: $screen-md-min) {
      left: 30px;
    }
  }

  &.next {
    right: 20px;

    @media (min-width: $screen-md-min) {
      right: 30px;
    }
  }
}

.carousel-image {
  min-width: 100%;
}

.carousel-caption {
  z-index: 14;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 15px;
  transform: translateZ(0);

  @media (min-width: $screen-sm-min) {
    padding: 20px;
  }

  @media (min-width: $screen-md-min) {
    padding: 25px;
  }

  @media (min-width: $screen-lg-min) {
    padding: 30px;
  }

  &:after {
    content: "";
    z-index: 13;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-image: linear-gradient(rgba($color-black, 0) 0%, rgba($color-black, 1) 100%);
    pointer-events: none;

    @media (min-width: $screen-sm-min) {
      //padding: 160px;
    }
  }

  p {
    z-index: 14;
    position: relative;
    text-align: left;
    margin-bottom: 0;
    line-height: 15px;
    max-width: 75%;
    font-size: 12px;
    font-family: $font-family-sans-serif;
  }
}

.carousel-indicator {
  z-index: 15;
  position: absolute;
  font-family: $font-family-brand-sc;
  font-size: 15px;
  text-align: right;
  bottom: 1em;
  right: 1em;
  height: 2em;
  transform: translateZ(0);

  @media (min-width: $screen-sm-min) {
    font-size: 20px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 25px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 30px;
  }

  .current-cell,
  .total-cells,
  .total-cells::before {
    display: inline-block;
  }

  .current-cell {
    position: relative;
    top: -25%;
    right: -0.375em;
  }

  .total-cells {
    &::before {
      content: "/";
      font-size: 1.5em;
      width: 0.5em;
      font-family: $font-family-brand;
    }
  }
}

.video-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-family: $font-family-brand-sc;
  color: rgba(#fff, 0.66);
  text-shadow: 1px 1px rgba(#000, 0.25);
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: rgba(#fff, 1);
    text-shadow: 1px 1px rgba(#000, 0.5);
  }

  .icon {
    fill: currentColor;
    margin-right: 10px;
  }
}

.video-carousel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-black;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .carousel-cell {
    height: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: $color-black;
    overflow: hidden;

    .carousel-image-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      img {
        @media (orientation: portrait) {
          min-height: 100%;
          height: 100%;
          width: auto;
        }

        @supports (object-fit: cover) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .overlay {
      position: absolute;
      opacity: 0;
      pointer-events: none;

      @media (max-width: $screen-xs-max) {
        opacity: 0;
      }
    }
  }

  &.flickity-enabled {
    opacity: 1;
  }

  .video-title {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px 30px;
    // display: block;
    // position: absolute;
    // bottom: 20px;
    // right: 30px;
    // z-index: 100;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    // }

    @media (max-width: $screen-xs-max) {
      left: 30px;
      text-align: center;
    }
  }
}

.select-cell {
  display: block;
  background: none;
  color: white;
  border: 2px solid $link-color;
  padding: 15px 25px;
  transition:
    border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  @media (min-width: $screen-sm-min) {
    padding: 15px 25px;

    &:hover {
      color: white;
      border: 2px solid white;
    }
  }

  .carousel-cell.is-selected & {
    background-color: white;
    border-color: white;
    color: $link-color;

    &:hover {
      color: $link-color;
    }
  }

  &:hover {
    .ale-trail-link {
      background-color: $link-active-color;
    }
  }
}

.carousel-control {

  .previous,
  .next {
    z-index: 100;
  }
}

.frontpage-landing {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  // height: 100vh;
  color: white;
  background-color: $color-black;
  overflow: hidden;
  // margin-top: 48px;
  margin-left: -15px;
  margin-right: -15px;

  @media (min-width: $screen-sm-min) {
    // margin-top: 0;
    min-height: 600px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.25;

    @include img-retina("../images/bg-dots.png", "../images/bg-dots@2x.png", 600px, 600px);
  }

  .frontpage-landing-video,
  .frontpage-landing-intro {
    z-index: 10;
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;

    @media (min-width: $screen-sm-min) {
      width: 50%;
      max-width: 50%;
    }
  }

  .frontpage-landing-video {
    @media (max-width: $screen-xs-max) {
      padding-top: 56.25%;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
    }
  }

  .frontpage-landing-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 45px 15px;
    margin-bottom: 60px;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      max-width: ($container-lg / 2) + 30px;
      padding: 60px 0 60px 100px;
      margin-bottom: 0;
      text-align: left;
    }

    .tagline {
      margin-bottom: 45px;
    }

    .lead {
      font-family: $font-family-serif;
      color: #fff;
      font-weight: normal;
      font-size: 20px;
      line-height: 1.5;
    }

    .view-ale-trails {
      margin-top: 45px;
    }
  }
}

// Blog
// ================================

.page-nav {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  background-color: $color-light-grey;

  a {
    display: inline-block;
    padding: 15px;
    color: $link-active-color;
    text-transform: uppercase;
  }
}

/* Single Posts */

.blog {
  .entry-summary {
    margin-top: 15px;
  }

  .updated,
  .author {
    //font-size: 18px;
    color: $color-mid-grey;

    & > a {
      color: $color-mid-grey;
    }
  }

  .entry-title {
    margin-bottom: 5px;
  }
}

.post {
  .author {
    display: inline-block;
  }

  blockquote {
    padding: 0;
    padding-left: 20px;
    margin: 30px;

    p {
      font-size: 24px;
    }
  }
}

.sharebox-container {
  text-align: center;
}

.sharebox {
  font-size: 24px;
  display: inline-block;
  list-style: none;
  margin-left: 0;
  margin-top: 20px;
  position: relative;
  @extend .list-inline;
  color: $color-mid-grey;
  font-family: $font-family-sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  border: 2px solid $border-color;
  max-width: 100%;

  li {
    padding: 5px 15px;
    border-left: 2px solid $border-color;
    float: right;

    &:first-of-type {
      float: left;
      border-left: 0;
      font-size: 18px;
      padding: 10px 15px 0px 15px;

      @media (min-width: $screen-sm) {
        padding: 10px 120px 0 15px;
      }
    }
  }
}

/* About Page */
.about-team {
  .about-team {
    padding-bottom: 75px;
  }

  .team-member {
    margin-bottom: 25px;

    @media (min-width: $screen-sm) {
      margin-bottom: 50px;
    }

    .entry-title {
      font-size: 24px;
      color: $color-bronze;
      margin-top: 0;
      margin-bottom: 6px;
    }

    .headshot {
      border-radius: 50%;
      position: relative;
    }

    .col-sm-4 {
      padding: 20px;
    }

    .description {
      color: $color-mid-grey;
      font-size: 18px;
      margin-bottom: 15px;
      display: block;
    }
  }

  .other-credits {
    font-weight: 700;
    margin-top: 20px;
    display: block;
  }
}

/* About Modal */
.about-overlay {
  color: #fff;
  background: rgba(0, 0, 0, 0.75);

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-inner {
    position: relative;
    top: 10%;

    h2 {
      font-size: 24px;
      font-family: $font-family-sans-serif;
    }

    .col-sm-12 {
      margin-bottom: 30px;
    }
  }

  .container {
    height: 100%;
    position: relative;
  }

  h2 {
    color: #fff;
  }

  .btn {
    position: absolute;
    top: 50px;
    margin-left: 12px;
  }
}

/* About the Project */
.about-project {
  h3 {
    margin-bottom: 20px;
  }
}

/* 404 */
.error404 {
  .main {
    h1 {
      font-family: $font-family-serif;
      font-size: 85px;
      line-height: 1;
    }
  }
}

/* Search Results */
.search {
  .search-form {
    margin-bottom: 40px;
  }

  article {
    border-bottom: 1px solid $color-light-grey;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .posts-navigation {
    border-top: 2px solid #ece4e3;
    font-family: clarendon-text-pro, Georgia, serif;
    font-size: 24px;
  }

  .posts-navigation {
    .nav-links {
      padding: 15px 0;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

.typeform-widget {
  @media (max-width: $screen-md-min) {
    height: 300px !important;
  }
}

body .itin {
  font-size: 1.6rem;

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.428571429;
    border-radius: 25px;
    color: #b58d61;
    background-color: #fff;
    border-color: #ece4e3;
  }

  .btn-default {
    color: #b58d61;
    background-color: #fff;
    border-color: #ece4e3;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 35px;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.428571429;
    color: #555;
    background-color: #fff !important;
    background-image: none;
    border: 1px solid #ece4e3;
    border-radius: 99em;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}

.itinerator-itinerary {
  background-color: transparent;

  .leg-title {
    background: none;
  }

  h2.itineray-name {
    color: #929495;
    margin-bottom: 20px;
    font-size: 30px;
  }

  .h4 {
    font-size: 20px;
  }

  .description {
    font-style: italic;
    font-size: 15px;

    li p {
      margin-bottom: 0px;
    }
  }

  .listing-link a {
    font-size: 16px;
  }

  .itinerary-row {
    border: 1px solid #ece4e3;
    padding-bottom: 3rem;
    padding-top: 3rem;
    // margin-bottom: 3rem;
    // margin-top: 3rem;

    .itinerary-column {
      display: table-cell;
      vertical-align: middle;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
}

.sip-stay {
  .page-header.page-header-archive {
    .description {
      // font-style: italic;
      font-size: 15px;
      margin-top: 0;

      li p {
        margin-bottom: 0px;
      }
    }

    .tags {
      margin: 10px 0;
    }
  }
}

.itinerator-events {
  .card-image-wrapper {
    aspect-ratio: 1 / 1;
  }

  .card-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
