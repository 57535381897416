/**
 * @license
 * MyFonts Webfont Build ID 3273156, 2016-08-22T18:07:29-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: PublicaSans-Bold by FaceType
 * URL: http://www.myfonts.com/fonts/facetype/publica-sans/bold/
 *
 * Webfont: PublicaSans-BoldItalic by FaceType
 * URL: http://www.myfonts.com/fonts/facetype/publica-sans/bold-italic/
 *
 * Webfont: PublicaSans-Regular by FaceType
 * URL: http://www.myfonts.com/fonts/facetype/publica-sans/regular/
 *
 * Webfont: PublicaSans-RegularItalic by FaceType
 * URL: http://www.myfonts.com/fonts/facetype/publica-sans/italic/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3273156
 * Licensed pageviews: 10,000
 *
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/31f1c4");

@font-face {
  font-family: 'PublicaSans';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/31F1C4_0_0.eot');
  src: url('../fonts/31F1C4_0_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/31F1C4_0_0.woff2') format('woff2'),
       url('../fonts/31F1C4_0_0.woff') format('woff'),
       url('../fonts/31F1C4_0_0.ttf') format('truetype'),
       url('../fonts/31F1C4_0_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: bold;
  font-style: italic;
  src: url('../fonts/31F1C4_1_0.eot');
  src: url('../fonts/31F1C4_1_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/31F1C4_1_0.woff2') format('woff2'),
       url('../fonts/31F1C4_1_0.woff') format('woff'),
       url('../fonts/31F1C4_1_0.ttf') format('truetype'),
       url('../fonts/31F1C4_1_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/31F1C4_2_0.eot');
  src: url('../fonts/31F1C4_2_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/31F1C4_2_0.woff2') format('woff2'),
       url('../fonts/31F1C4_2_0.woff') format('woff'),
       url('../fonts/31F1C4_2_0.ttf') format('truetype'),
       url('../fonts/31F1C4_2_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: 600;
  font-style: normal;
  src: url('../fonts/PublicaSans-Medium.eot');
  src: url('../fonts/PublicaSans-Medium.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PublicaSans-Medium.woff2') format('woff2'),
       url('../fonts/PublicaSans-Medium.woff') format('woff'),
       url('../fonts/PublicaSans-Medium.ttf') format('truetype'),
       url('../fonts/PublicaSans-Medium.svg#wf') format('svg');
}

@font-face {
  font-family: 'PublicaSans';
  font-weight: normal;
  font-style: italic;
  src: url('../fonts/31F1C4_3_0.eot');
  src: url('../fonts/31F1C4_3_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/31F1C4_3_0.woff2') format('woff2'),
       url('../fonts/31F1C4_3_0.woff') format('woff'),
       url('../fonts/31F1C4_3_0.ttf') format('truetype'),
       url('../fonts/31F1C4_3_0.svg#wf') format('svg');
}

@font-face {
  font-family: 'GreatForest';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/GreatForestv2-Simplified.eot');
  src: url('../fonts/GreatForestv2-Simplified.eot?#iefix') format('embedded-opentype'),
       url('../fonts/GreatForestv2-Simplified.woff2') format('woff2'),
       url('../fonts/GreatForestv2-Simplified.woff') format('woff'),
       url('../fonts/GreatForestv2-Simplified.ttf') format('truetype');
}

@font-face {
    font-family: 'GreatForestSmallCaps';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/great_forest_-_small_caps-webfont.eot');
    src: url('../fonts/great_forest_-_small_caps-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/great_forest_-_small_caps-webfont.woff2') format('woff2'),
         url('../fonts/great_forest_-_small_caps-webfont.woff') format('woff'),
         url('../fonts/great_forest_-_small_caps-webfont.svg') format('svg');
}
