// Grid system
// ==================================

.wrap {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  @include container-fixed;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  @media (min-width: $screen-md-min) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media (min-width: $screen-lg-min) {
    max-width: 1360px;
    padding-right: 45px;
    padding-left: 45px;
  }
}

.container-fluid {
  @include container-fixed;
}

.row {
  @include make-row;
}

@include make-grid-columns;

@include make-grid(xs);

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}

.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}
