// Cards
.card {
  border: 1px solid $border-color;

  .thumbnail {
    border: 0;
    margin-top: -$card-padding;
    margin-left: -$card-padding;
    margin-bottom: $card-padding;
    margin-right: -$card-padding;
    padding: 0;
    position: relative;
    min-height: 260px;
    max-width: none;
    overflow: hidden;

    .thumbnail-image,
    .thumbnail-video {
      max-width: 100%;
      height: auto;
    }

    @supports ( object-fit: cover ) or ( -o-object-fit: cover ) {
      padding-top: 56.25%;

      .thumbnail-image,
      .thumbnail-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }

    }

    a {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  h3 {
    color: $title-color;
  }
}

.card-body {
  padding: $card-padding;

  p {
    font-family: $font-family-sans-serif;
  }
}

.card-footer {
  padding: $card-padding;

  .icon-container {
    border-top: 1px solid $border-color;
  }
}

// Card Row
.card-row {
  padding-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  &::before,
  &::after {
    content: none;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-12 {
    position: relative;
    margin-bottom: 30px;
  }

  @media ( max-width: $screen-sm-max ) {
    .col-12 {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 30px;
      flex: 1;
      min-width: 100%;
      max-width: 100%;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card-body {
    flex: 1 1 auto;

    .distance {
      font-size: 1.4rem;
      color: $color-dark-grey;
      font-weight: bold;

      .unit {
        color: $color-mid-grey;
        font-weight: normal;
        margin-left: 5px;
      }
    }

    .card-text {
      // padding: $card-padding;

      p {
        line-height: 1.5;
      }
    }
  }
}

.activity-card {
  .thumbnail {
    padding-top: 75%;
    min-height: auto;

    a {
      padding-top: 0;
      top: 15px;
      left: 15px;
      bottom: 0;
      right: 15px;

      > img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .card-body {
    // padding-bottom: 0;
  }

  .card-footer {
    padding-top: 0;

    .btn {
      margin-left: 0;
    }
  }
}

.card-ale-trail-horizontal {
  display: flex;
  position: relative;
  align-items: center;

  .card-thumb {
    width: 25%;
    padding: 0 15px;

    .thumbnail {
      padding-top: 100%;
      min-height: auto;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .card-body {
    width: 75%;

    p {
      font-family: $font-family-serif;
      font-size: 12px;
      color: lighten($text-color, 25%);
    }
  }

  .card-title {
    font-size: 16px;
    font-weight: 600;

    display: inline-block;
  }

  .card-link {
    display: block;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
