
.section-app {
  padding: 0;
  background-color: $color-blue;
  overflow: hidden;
  color: #fff;

  .container {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    @media ( min-width: $screen-sm-min ) {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  h2 {
    color: $color-yellow;
    font-size: 35px;

    small {
      color: currentColor;
      font-weight: bold;
    }
  }

  p {
    font-size: 20px;
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  @include img-retina("../images/bg-dots.png","../images/bg-dots@2x.png",600px,600px);

  .cta-wrap {
    @media ( min-width: $screen-sm-min ) {
      // width: 50%;
    }

    @media ( min-width: $screen-md-min ) {
      width: 50%;
    }
  }

  .cta-illustration {
    position: absolute;
    width: 50%;
    // top: 0;
    right: 0;
    bottom: 0;
  }
}

.app-illustration {
  // 1200 × 1184
  @include img-retina("../images/app-illustration-new.png","../images/app-illustration-new@2x.png", 1200 px, 1184px);
  position: absolute;
  right: 0;
  bottom: 115px;
  width: 100%;
  height: 300px;
  transform: translateY(38%);
  // width: 1200px;
  //height: 1184px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;

  @media ( min-width: $screen-md-min ) {
    background-size: cover;
    background-position: left;
    bottom: 190px;
    height: 498px;
  }

  @media ( min-width: 1955px ) {
    background-size: contain;
    background-position: right;
    bottom: 190px;
    height: 498px;
  }
}

.app-store-icon {
  height: 50px;
  width: auto;
}

.app-store-links {
  margin-top: 60px;
  margin-bottom: 30%;

  @media ( min-width: $screen-sm-min ) {
    margin-bottom: 0;
    width: 50%;
  }

  @media ( min-width: $screen-md-min ) {
    width: 100%;
  }


  a {
    @media ( min-width: $screen-sm-min ) {
      width: 50%;
    }
  }

  .app-store-icon {
    position: relative;
    z-index: 99;
    margin-top: 30px;
    margin-right: 30px;
  }
}
