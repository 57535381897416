// The Width of a Circle
$btn-circle-width-small:      30px;
$btn-circle-width:            37px;
$btn-circle-width-large:      50px;

$btn-circle-font-size:        20px;
$btn-circle-font-size-large:  30px;

.btn-circle {
  display: inline-block;
  font-family: $font-family-brand-sc;
  width: $btn-circle-width;
  height: $btn-circle-width;
  padding: (($btn-circle-width - $btn-circle-font-size) / 2);

  border: 2px solid $button-border;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: none;

  color: $button-border;
  font-size: 20px;
  line-height: 0.5;
  text-align: center;
  &:hover,
  &:focus {
    box-shadow: none;
    color: $link-color;
  }
  &.btn-sm {
    width: $btn-circle-width-small;
    height: $btn-circle-width-small;
    padding: (($btn-circle-width-small - $btn-circle-font-size) / 2);
  }
  &.btn-lg {
    width: $btn-circle-width-large;
    height: $btn-circle-width-large;
    padding: (($btn-circle-width-large - $btn-circle-font-size-large) / 2);
    font-size: $btn-circle-font-size-large;
  }
}

.btn-circle.btn-white {
  border-color: $color-white;
  color: $color-white;
  &:hover {
    color: $color-off-white;
  }
}

.btn {
  border-width: 2px;
  transition: color 0.2s cubic-bezier($cubic-bezier),
   background-color 0.2s cubic-bezier($cubic-bezier),
       border-color 0.2s cubic-bezier($cubic-bezier);

  i.fa {
    &:first-child {
      margin-right: 0.25em;
    }
  }

  svg.icon {
    vertical-align: middle;
    margin-right: 4px;
  }
}

.btn-sm {
  border-radius: 100px;
}

.btn-lg {
  padding: 10px 26px;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(#fbbd13, 0.6);
}

.btn-group-lg>.btn,
.btn-lg,
.comment-form .btn-group-lg>input[type=submit],
.search-form .btn-group-lg>.search-submit {
  font-size: 15px;
  //line-height: 1.2;
  @media ( min-width: $screen-sm-min ) {
    font-size: 20px;
  }

  @media (min-width: $screen-md-min) {
    //padding: 16px 30px;
  }
}

.btn-regular {
  @include button-variant($color-brown, $color-white, $border-color);
}

.btn-ghost {
  @include button-variant($color-white, rgba($color-white, 0), rgba($color-white, 0.5));

  &:hover,
  &:focus {
    background-color: $color-white;
    color: $brand-primary;
    border-color: $color-white;
  }
}

.btn-action {
  @include button-variant($color-white, $link-color, $link-color);
}

.btn-square {
  border-radius: 0;
}

.btn-invisible {
  padding: 0;
  border: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  img {
    margin: 0 !important;
  }
}

.filter-checkbox {
  input[type=checkbox] {
    margin-top: 0;
  }
  .control-label {
    position: relative;
    top: -1px;
  }
}

.btn-cta {
  border-radius: 0;
}
