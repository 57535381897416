// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: 50px auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($line-height-computed / 2);
  height: auto;
}
@media (min-width: $screen-sm-min) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($line-height-computed / 2);
  }
  .alignright {
    float: right;
    margin-left: ($line-height-computed / 2);
  }
}

// Captions
.wp-caption {
  @extend .thumbnail;
}
.wp-caption-text {
  padding: $thumbnail-caption-padding;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}

/*--------------------------------------------------------------
## Galleries (Lifted from twentynineteen)
--------------------------------------------------------------*/
.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: calc(1.5 * 1rem);
}

.gallery-item {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: calc((100% - 16px * 1) / 2);
}

.gallery-columns-2 .gallery-item:nth-of-type(2n+2) {
  margin-right: 0;
}

.gallery-columns-3 .gallery-item {
  max-width: calc((100% - 16px * 2) / 3);
}

.gallery-columns-3 .gallery-item:nth-of-type(3n+3) {
  margin-right: 0;
}

.gallery-columns-4 .gallery-item {
  max-width: calc((100% - 16px * 3) / 4);
}

.gallery-columns-4 .gallery-item:nth-of-type(4n+4) {
  margin-right: 0;
}

.gallery-columns-5 .gallery-item {
  max-width: calc((100% - 16px * 4) / 5);
}

.gallery-columns-5 .gallery-item:nth-of-type(5n+5) {
  margin-right: 0;
}

.gallery-columns-6 .gallery-item {
  max-width: calc((100% - 16px * 5) / 6);
}

.gallery-columns-6 .gallery-item:nth-of-type(6n+6) {
  margin-right: 0;
}

.gallery-columns-7 .gallery-item {
  max-width: calc((100% - 16px * 6) / 7);
}

.gallery-columns-7 .gallery-item:nth-of-type(7n+7) {
  margin-right: 0;
}

.gallery-columns-8 .gallery-item {
  max-width: calc((100% - 16px * 7) / 8);
}

.gallery-columns-8 .gallery-item:nth-of-type(8n+8) {
  margin-right: 0;
}

.gallery-columns-9 .gallery-item {
  max-width: calc((100% - 16px * 8) / 9);
}

.gallery-columns-9 .gallery-item:nth-of-type(9n+9) {
  margin-right: 0;
}

.gallery-item:last-of-type {
  padding-right: 0;
}

.gallery-caption {
  display: block;
  font-size: 0.71111em;
  line-height: 1.6;
  margin: 0;
  padding: 0.5rem;
}

.gallery-item > div > a {
  display: block;
  line-height: 0;
  box-shadow: 0 0 0 0 transparent;
}

.gallery-item > div > a:focus {
  box-shadow: 0 0 0 2px $link-active-color;
}
