body.menu-active {
  @extend .modal-open;
  position: fixed;
  width: 100%;
}

.banner {
  z-index: 750;
  // position: relative;
  position: sticky;
  top: -83px;

  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 2px 0px rgba(#0b0201, 0.1);
  transition: transform 0.2s cubic-bezier($cubic-bezier),
             box-shadow 0.2s cubic-bezier($cubic-bezier),
       background-color 0.4s cubic-bezier($cubic-bezier);
  transform: translateZ(0);

  @media (max-width: $screen-xs-max) {
    top: 0;
  }

  > .container {
    position: relative;
    //background-color: $color-white;
  }

  .nav-primary {
    transition: transform 0.2s cubic-bezier($cubic-bezier), opacity 0.2s cubic-bezier($cubic-bezier);
  }

  .dropdown-menu {
    right: 0;
    left: auto;
  }

  .admin-bar & {
    @media ( min-width: $screen-sm-min ) {
      top: -83px + 32px;
    }
  }
}

.navigation-top,
.navigation-bottom {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-align: center;
  box-pack: justify;
  .nav-hashtag,
  .nav-about {
    @media (max-width: $screen-xs-max) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .nav-controls,
  .nav-connect {
    @media (max-width: $screen-xs-max) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .nav-hashtag,
  .nav-controls,
  .nav-about,
  .nav-connect {
    @media (min-width: $screen-sm-min) {
      flex: 1 1 auto;
    }
  }
  .nav-brand {
    margin: 0 auto 0 0;
  }
  .nav-primary {
    margin: 0 auto;
    @media (min-width: $screen-sm-min) {
      flex: 1 auto;
    }
  }
  .menu-home {
    display: none !important;
  }
}

.has-fullscreen-landing header.banner {
  .navigation-top {
    display: none;
    /*
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    */
  }

  &.menu-active {
    //display: block;
  }
}

.navigation-top {
  transition: border 0.1s cubic-bezier($cubic-bezier);
  @media (min-width: $screen-sm-min) {
    border-bottom: 1px solid rgba(darken($border-color, 10%), 0.5);
  }
  .banner.sticky &,
  body.has-fullscreen-landing &,
  body.menu-active & {
    @media (min-width: $screen-sm-min) {
      border-bottom: 2px solid transparent;
    }
  }
}

.nav-primary {
  text-align: left;
  line-height: 0;
  @media (max-width: 1000px) {
    // display: none;
  }
  .nav {
    .menu-item {
      font-family: $font-family-sans-serif;
      font-weight: 700;
      font-size: 18px;
      text-transform: uppercase;
      line-height: 1;
      @media (min-width: $screen-sm-min) {
        display: inline-block;
        margin-left: 0px;
        margin-right: 10px;
      }
      &.active {
        a {
          color: $logo-color;
          border-bottom: 6px solid $border-color;
        }
      }
      a {
        padding: 24px 10px;
        transition: all 0.2s cubic-bezier($cubic-bezier);

        &:first-child {
         padding-left: 0;
        }

        &:hover {
          color: $link-active-color;
        }
      }
    }
  }
}

.nav-about {
  text-align: right;

  .dropdown {
    display: inline-block;
  }

  .btn-about {
    position: relative;
    color: $title-color;
    padding: 0 8px 0 0;

    &::after {
      content: '\f107';
      display: inline-block;
      vertical-align: middle;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-top: -2px;
      font-size: 1.5em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.beer-finder-btn {
  font-weight: normal;
  border: none;
  color: $title-color;
  background-color: $color-light-grey;
  font-size: 1.125em;
  padding: 10px 25px 12px 25px;
  border-radius: 30px;
  margin-left: 10px;

  .icon {
    fill: $color-bronze;
    vertical-align: middle;
    margin-right: 4px;
  }
}

.nav-connect {
  padding: 15px 10px 15px 15px;
  .list-social {
    font-size: 20px;
    margin-bottom: 0;
    a {
      color: $logo-color;
      .icon {
        fill: $logo-color;
      }
      &:hover,
      &:focus {
        color: $logo-hover-color;
        .icon {
          fill: $logo-hover-color;
        }
      }
    }
  }
}

.nav-hashtag {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  //position: absolute;
  //bottom: 0;
  //left: 15px;
  a {
    display: inline-block;
    padding: 14px 15px;
    margin-left: -15px;
    //font-size: 20px;
    @media (min-width: $screen-sm-min) {
      margin-left: 0;
      padding: 20px 15px;
    }
  }
  .fa {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  span {
    margin-left: 6px;
    max-width: 150px;
    line-height: 1.1;
    display: inline-block;
    vertical-align: middle;
  }
}

.nav-controls {
  //position: absolute;
  //bottom: 0;
  //right: 15px;
  text-align: right;
}

.nav-connect {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btn-app {
    display: flex;
    align-items: center;
    border: 1px solid $link-color;
    border-radius: 100px;
    line-height: 1.1;
    padding: 0.6rem 1.8rem 0.8rem;
    margin-left: 2rem;
    color: $color-black;

    .fas {
      font-size: 3rem;
      margin-right: 10px;
    }

    span {
      text-align: left;
      display: block;
    }

    &:hover,
    &:focus {
      border-color: $color-seafoam;
      background-color: $color-seafoam;
      color: white;
    }
  }
}

.toggle-menu {
  padding: 23px 15px;
  margin-right: -15px;
  position: relative;
  overflow: hidden;
  outline: 0 solid transparent;
  background-color: transparent;
  border: 0px solid transparent;
  @media (min-width: $screen-sm-min) {
    margin-right: 0;
    padding: 32px 15px;
  }
  .border,
  .border:before,
  .border:after {
    width: 25px;
    height: 2px;
    background: $link-color;
    transition: all 0.2s cubic-bezier($cubic-bezier);
    transform-origin: left;
    @media (min-width: $screen-sm-min) {
      width: 30px;
      height: 4px;
    }
  }
  &:hover,
  &:focus {
    .border,
    .border:before,
    .border:after {
      background: $link-active-color;
    }
  }
  &:hover {
    .border {
      transform: scaleX(0.5);
      transform-origin: left;
      &:before {
        transform: scaleX(2);
        transform-origin: left;
      }
      &:after {
        transform: scaleX(1.5);
        transform-origin: left;
      }
    }
  }
  .border {
    display: block;
    position:relative;
    &:before {
      content:'';
      position:absolute;
      top: -5px;
      left: 0;
      @media (min-width: $screen-sm-min) {
        top: -8px;
      }
    }
    &:after {
      content:'';
      position:absolute;
      top: 5px;
      left: 0;
      @media (min-width: $screen-sm-min) {
        top: 8px;
      }
    }
  }
  body.menu-active & {
    .border {
      transform: translateX(30px) scale(0.5);
      background: transparent;
      &:before {
        transform: translateX(-60px) rotate(45deg) scale(2);
        top: -18px;
        @media (min-width: $screen-sm-min) {
          top: -21px;
        }
      }
      &:after {
        transform: translateX(-60px) rotate(-45deg) scale(2);
        top: 18px;
        @media (min-width: $screen-sm-min) {
          top: 21px;
        }
      }
    }
  }
}

.nav-sitemap {
  // Position
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: $zindex-navbar-fixed;

  // Transition Styling
  transition: opacity 0.4s ease-in-out, transform 0s ease-in-out 0.4s;
  opacity: 0;
  transform: translateX(-100%);

  // Looks
  background-color: white;

  .container {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .nav {
    @media (min-width: $screen-sm-min) {
      //float: left;
      //width: 50%;
      padding: 0 15px;
      margin-bottom: 20px;
    }
    .menu-item {
      float: left;
      clear: both;
      display: inline-block;
    }
  }

  .primary-nav {
    font-size: 25px;
    font-family: $font-family-serif;
    @media (min-width: $screen-sm-min) {
      font-size: 30px;
    }
  }

  .secondary-nav {
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.5;
    font-family: $font-family-sans-serif;
    font-weight: bold;
  }

  .nav-connect {
    text-align: left;

    @media ( min-width: $screen-sm-min ) {
      padding-left: 25px;
    }

    .btn-app {
      margin-right: auto;
      margin-left: 0;
    }
  }

  .nav-controls {
    position: absolute;
    top: 0;
    right: 20px;
    @media ( min-width: $screen-sm-min ) {
      right: 30px;
    }
    @media ( min-width: $screen-lg-min ) {
      right: 45px;
    }
  }

  body.menu-active & {
    // Transition Styling
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.4s ease-in-out, transform 0s ease-in-out 0s;
  }
}

.brand,
.bcat-logo {
  width: 120px;
  height: 40px;
  @media (min-width: $screen-sm-min) {
    width: 180px;
    height: 27px;
  }
  @media (min-width: $screen-md-min) {
    width: 242px;
    height: 32px;
  }
}

.brand {
  display: block;
  margin: 4px auto;
  @media (min-width: $screen-sm-min) {
    margin: 25px auto;
  }
  .sr-only {
    position: absolute;
  }
  .bcat-logo {
    // Viewbox: 240 80
    fill: $logo-color;
    transition: fill 0.2s cubic-bezier($cubic-bezier);
  }
  &:hover .bcat-logo {
    fill: $logo-hover-color;
  }
}

@keyframes slideInUp {
  0%   { transform: translateY(0); }
  10%   { transform: translateY(25px); }
  75%   { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

.bounce {
  animation: slideInUp 0.2s 0s 1 cubic-bezier($cubic-bezier);
}
