.gallery-carousel {
  opacity: 0;
  transition: opacity 0.4s ease-in;

  &.flickity-enabled {
    // opacity: 1;
  }

  &.images-loaded {
    opacity: 1;
  }

  .carousel-cell {
    color: $border-color;
    position: relative;
    // width: 100%;
    background-color: $color-black;
    // max-width: 1270px;
    margin: 0 15px;
    max-width: 100vw;
    max-width: calc(100vw - 30px);

    .carousel-image-container {
      position: relative;
      // padding-top: 56.25%;
      background-color: $border-color;
      overflow: hidden;
      height: 720px;

      @media (max-width: $screen-sm-max) {
        height: 320px;
      }

      .carousel-image {
        width: auto;
        height: 100%;
        max-width: none;
        min-width: 0;
      }
    }

    .overlay {
      z-index: 10;
      position: absolute;
      background-color: rgba($color-black, 0.8);
      transition: opacity 0.4s cubic-bezier($cubic-bezier);
      pointer-events: none;
    }

    &.is-selected .overlay {
      opacity: 0;
      transition: opacity 0.6s cubic-bezier($cubic-bezier) 0.4s;
    }
  }
}

// Gallery Carousel / Lightbox / Modal
.gallery-modal {
  @extend .about-overlay;

  background: rgba(0,0,0,0.95) !important;

  .gallery-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    .gallery-carousel {
      margin-top: 0;
      opacity: 0;
    }

    &::after {
      // Loading indicator
      @extend .loading-spinner;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;

      // Transition for fade in/out
      transition: opacity 0.2s ease-in;
      pointer-events: none;
      opacity: 1;
    }

    &.carousel-ready {
      .gallery-carousel {
        opacity: 1;
        transition: opacity 0.6s ease-in 0.4s;
      }

      &::after {
        opacity: 0;
        transition: opacity 0.2s ease-in, top 0 linear 0.2s;
        transform: none;
      }
    }
  }

  .gallery-carousel {
    margin-top: 30px;

    .carousel-cell {
      @media (max-width: $screen-sm-max) {
        .carousel-image-container {
          background-color: transparent;

          .overlay {
            display: none;
          }

          .carousel-caption {
            margin-left: -45px;
            margin-right: -45px;
            margin-bottom: -30px;
            padding-left: 60px;
            padding-right: 60px;
            padding-bottom: 45px;

            &::after {
              background-image: linear-gradient(rgba(0,0,0,0),black);
            }
          }
        }
      }

      .carousel-caption {
        padding: 15px;

        p {
          max-width: none;
          margin-right: 50px;
        }

        &::after {
          padding: 0;
          height: 175%;
        }
      }

      .carousel-indicator {
        @media (min-width: $screen-sm-min) {
          font-size: 15px;
        }
      }

      .carousel-indicator .current-cell {
        right: 0;
      }
    }
  }

  &.show {
    opacity: 1;

    .modal-dialog {
      transform: translateY(0);
    }
  }
}
