.section-recent-posts .updated {
  color: $color-mid-grey;
}

.entry-title {
  a {
    color: $color-brown;

    &:hover,
    &:focus {
      color: $link-color;
    }
  }
}

article.post {

  @media (max-width: $screen-xs-max) {
    //margin-bottom: 20px;
    .entry-summary {
      display: none;
    }
  }
  header {
    margin-bottom: 10px;
  }
  .entry-summary {
    margin-bottom: 10px;
  }
  .tags {
    font-size: 12px;
    text-transform: uppercase;
    color: $color-brown;
    font-weight: 700;
    a {
      color: $color-brown;
    }
  }
  .categories {
    font-size: 12px;
    text-transform: uppercase;
    color: $color-brown;
    font-weight: 700;
    a {
      color: $color-brown;
      &:hover {
        color: $link-color;
      }
    }
  }
  .entry-content {
    p > img {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

blockquote p {
  line-height: 1.45;
}

.single {
  article {
    .page-header-inner {
      @extend .section;

      color: $page-header-text-color;
      background-color: $page-header-bg;
      text-align: center;
    }
  }
}

.thumbnail, .wp-caption {
  border-color: $border-color;
  border-radius: 0;
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.wp-caption-text {
  font-size: 12px;
  color: $color-mid-grey;
}

article footer {
  .updated,
  .author {
    font-size: 14px;
  }

  .author {
    a {
      &:hover {
        color: $link-color;
      }
    }
  }
}

.entry-content-asset {
  // hope this is how it should be done
  @extend .embed-responsive;

  // 16by9
  @extend .embed-responsive-16by9;

  iframe {
    @extend .embed-responsive-item;
  }
}

// Featured Post
// ==============================
.featured-post {
  @extend .section;

  position: relative;
  margin-top: 0;
  margin-bottom: 0;
	background-repeat: no-repeat;
	background-size: 100%;
	background-size: cover;
	background-position: center center;
  color: $text-color;

	.container {
  	position: relative;
  	z-index: 20;
	}

  .overlay {
    z-index: 10;
    position: absolute;
    background-color: rgba($color-black, 0.8);
    transition: opacity 0.4s cubic-bezier($cubic-bezier);
    pointer-events: none;
  }

  .feature-title {
    @extend .content-over-photo;
    margin-left: 0;
    min-height: 0;
    text-align: left;

    @media (min-width: $screen-md-min) {
      max-width: 50%;
    }
    header {
      margin-bottom: 50px;
    }
    .subtitle {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 5px;
      color: $color-mid-grey;
    }
    .entry-title {
      // margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .entry-summary {
    display: none;
    @media ( min-width: $screen-sm-min ) {
      display: block;
    }
  }
}

// Type Labels
.type-label {
  font-family: $font-family-sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: $color-brown;
  //padding: 0;
  display: inline-block;
  border: 1px solid;
  padding: 2px 8px;
}

.type-label-tasting-notes {
  border-color: $color-red;
  background-color: $color-red;
  color: $color-white;
}

.type-label-blog {
  border-color: $color-seafoam;
  background-color: $color-seafoam;
  color: $color-white;
}

.type-label-podcasts {
  border-color: $color-light-green;
  background-color: $color-light-green;
  color: $color-white;
}

.type-label-brewery {
  background-color: mix($color-yellow, $color-orange, 50%);
  border-color: mix($color-yellow, $color-orange, 50%);
  color: $color-white;
}

.type-sip-stay-features {
  border-color: $color-light-green;
  background-color: $color-light-green;
  color: $color-white;
  margin: 5px;
}

.type-sip-stay-seasons {
  border-color: $color-brown;
  background-color: $color-brown;
  color: $color-white;
  margin: 5px;
}

// TODO: this is temporary and should be handled in the backend for all directory listing categories
.type-label-liquor-store {
  background-color: $color-yellow;
  border-color: $color-yellow;
  color: $color-white;
}

// Frontpage
// ==============================
.section-recent-tasting-notes {
/*
  padding-top: 0;
  padding-bottom: 0;
*/
/*
  .author-profile-name {
    font-size: 15px;
  }
  .author-profile-image {
    margin-right: 5px;
  }
  //.author-profile,
  .author-profile-name,
  .author-profile-image {
    float: none;
    display: inline-block;
    vertical-align: middle;
  }
  .author-profile {
    padding: 5px;
    //margin-right: 30px;
    text-align: center;
  }
*/
}

// Blog Frontpage (Home)
// ==============================
article {
  .feat-img{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background-color: $link-active-color;
      transition: opacity 0.2s ease-in-out;
      mix-blend-mode: multiply;

      &:hover,
      &:focus {
        opacity: 0.3;
      }
    }
  }
}

.section-news,
.section-tasting-notes {
  article {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-bottom: 1px solid $color-light-grey;

    header {
      flex: 1 1 auto;
    }

    &:last-of-type {
      border-bottom: none;
    }

    .feat-img{
      margin-bottom: 20px;
      padding-top: 56.25%;
      width: 100%;
      height: 250px;

      @media ( min-width: $screen-sm-min ) {
        margin-bottom: 0;
        height: 100%;
      }

      @media ( min-width: $screen-md-min ) {
        height: 250px;
      }
    }
  }

	.posts-navigation{
		border-top: 2px solid $color-light-grey;
		font-family: $font-family-serif;
		font-size: 24px;
		.nav-links{
			@extend .clearfix;
			padding: 15px 0;
		}
		.nav-previous{
			display: inline-block;
			float: left;
		}
		.nav-next{
			display: inline-block;
			float: right;
		}
	}
}


article.post {
  &:first-of-type {
    padding-top: 0;
  }
  footer {
    font-size: 14px;
    color: $color-mid-grey;
    a {
      color: $color-mid-grey;
    }
  }
}

.row-eq-height {
  .post-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.tasting-notes-post-row {
  .subtitle {
    margin-bottom: 5px;
    font-size: 1.4em;
  }

  .entry-title {
    margin-top: 10px;
  }
}

.section-tasting-notes {
  .col-md-4 {
    @media ( min-width: $screen-sm-min ) {
      //padding-right: 14px;
      border-right: 1px solid $border-color;
    }

    &:last-child {
      //padding-right: 15px;
      border-right: none;
    }
  }
  article.tasting-notes {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $border-color;

    @media ( min-width: $screen-sm-min ) {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    .feat-img {
      margin-bottom: 20px;
    }
    .entry-title {
      //font-size: 25px;
      //font-family: $font-family-brand;
      margin-top: 0;
      margin-bottom: 15px;
    }
    .subtitle {
      color: $color-mid-grey;
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
}

// Podcasts
.section-podcasts,
.feat-img-podcasts {
  position: relative;
  color: $color-light-grey;
  background-color: $podcasts-bg;
  @include img-retina("../images/bg-dots.png","../images/bg-dots@2x.png",600px,600px);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    @include img-retina("../images/bg-podcasts.png", "../images/bg-podcasts@2x.png", 576px, 583px);
    background-position: left bottom;
    background-repeat: no-repeat
    ;
  }

  .content-body {
    margin-bottom: 30px;
    .content-title {
      margin-bottom: 10px;
      color: inherit;
    }
    p {
      color: $color-off-white;
    }

    p a {
      color: $color-off-white;
      text-decoration: underline;
    }

    .link-all {
      color: white;
    }

    a {
      &:hover,
      &:focus {
        color: $link-color;
        text-decoration: none;
      }
    }
  }
}

.feat-img-podcasts::before {
  background-size: (576px * 0.5) (583px * 0.5) !important;
}

.recent-podcasts {
  padding: 25px;
  background-color: $color-off-white;
  color: $color-mid-grey;

  article {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid $color-light-grey;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    header {
      margin-bottom: 0;
    }

    time {
      //font-size: 14px;
    }
  }

  .podcast-title {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 0;
  }

  .podcast-icon {
    color: rgba($link-color, 0.5);
  }
}

// Newsletter CTA
.tasting-notes-coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 25px;
  @include img-retina("../images/bg-dots.png","../images/bg-dots@2x.png",600px,600px);
  background-color: $color-light-grey;
  color: $color-mid-grey;

  .title,
  .intro,
  .lead {
    line-height: 1.2;
    color: $link-active-color;
    margin-bottom: 10px;
  }

  .mc-field-group {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .mailchimp-form {
    width: 100%;
    max-width: 264px;
    margin: 15px auto 0 auto;
    input {
      width: 100%;
    }
    .btn {
      width: 100%;
      display: block;
      background-color: $link-active-color;
      border-color: $link-active-color;
      color: white;

      &:hover,
      &:focus {
        background-color: $link-color;
        border-color: $link-color;
      }
    }
  }
}

// Road Trip Journals (tasting-notes)
// ============================

.tasting-notes-meta {
  .author-profile-image-small {
    margin-right: 10px;
  }
  .thumb-wrap,
  .meta-wrap {
    vertical-align: middle;
    display: inline-block;
  }
  .author-profile-name,
  .updated {
    line-height: 1.2;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 0;
    color: $color-mid-grey;
  }

  a {
    color: $color-mid-grey;
  }
}

// Single Road Trip Journals (tasting-notes)
.split-header {
  display: flex;
  height: auto !important;
  min-height: 100vh;
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: $color-black !important;
  justify-content: stretch;
}

.split-header-container {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  // height: 100%;
  width: 100%;
}

.split-header-contents,
.split-header-media {
  width: 100%;
  max-width: 100%;

  @media ( min-width: $screen-md-min ) {
    width: 50%;
    max-width: 50%;
  }
}

.split-header-contents {
  position: relative;
  padding: 80px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $color-black;
  z-index: 5;

  > * {
    max-width: 640px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .fullscreen-landing & {
    padding: 150px 60px 170px 60px;
  }

  .tasting-notes-intro {
    margin-top: 30px;
    position: relative;

    &::before {
      content: "";
      display: block;
      height: 2px;
      background-color: $border-color;
      width: 120px;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }

  .category-pills {
    margin-top: 30px;

    .btn-regular {
      font-size: 14px;
      background: none;
      color: rgba($border-color, 0.5);;
      border-width: 1px;
      border-color: rgba($border-color, 0.5);
      margin-bottom: 12px;

      &:hover {
        color: $border-color;
        border-color: $border-color;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.25;
    z-index: -1;

    @include img-retina("../images/bg-dots.png","../images/bg-dots@2x.png",600px,600px);
  }

  .scroll-prompt {
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    $arrow-color: $color-bronze;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 1px;
      background-color: $arrow-color;
      margin: 0 auto;
    }

    &::before {
      height: 60px;
      margin-bottom: 6px;
    }

    &::after {
      height: 20px;
      margin-top: 6px;
    }

    .scroll-prompt-arrow {
      position: absolute;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $arrow-color;
    }

    &:hover {
      color: white;
    }
  }
}

.split-header-media {
  // background-color: blue;
}

.split-header-media-image {
  width: 100%;
  height: auto;

  @media ( min-width: $screen-md-min ) {
    @supports ( object-fit: cover ) {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.single .tasting-notes {
  .section-itinerary {
    .container-fluid {
      padding: 0;
      .itinerary-column:first-child {
        z-index: 10;
        transition: all 200ms cubic-bezier($cubic-bezier);
        &.map-active {
          //opacity: 0;
          transform: translateX(-100%);
        }
      }
      .author-profile {
        padding: 30px;
      }
    }

    .itinerary-control {
      border-top: 2px solid $border-color;
      margin-top: 30px;
      margin-left: 30px;
      margin-right: 30px;
      // padding-left: 30px;
      // padding-right: 30px;
    }
  }

  // Transparency for map breaks
  .itinerary-location {
    margin-bottom: 0;
    padding: 0px 30px 30px 30px;
    background-color: rgba(white, 0.9);
    @media (min-width: $screen-md-min) {
      background-color: white;
    }
    &.after-break {
      @media (max-width: $screen-sm-max) {
        padding: 30px;
      }
    }
  }

  .itinerary-break {
    box-shadow: inset 0px 2px 0px rgba(#0b0201, 0.1);
    @media (min-width: $screen-md-min) {
      display: none;
    }
    + .itinerary-location {
      @media (max-width: $screen-sm-max) {
        padding-top: 30px;
      }
    }
  }

  .itinerary-day-header {
    padding-top: 50px;
    background-color: rgba(white, 0.9);
    h4 {
      color: $color-light-green;
      margin: 0;
    }
  }

  .itinerary-day {
    @include clearfix();
    &:last-child {
      border-bottom: none;
    }
  }

  .itinerary-travel-info,
  .itinerary-locations {
    border: none;
  }

  .itinerary-locations {
    padding: 0;
  }

  .itinerary-location {
    margin-left: 0;
    &::after {
      content: none;
    }
  }

  .itinerary-map {
    .acf-map > div {
      transform:translate3d(0,0,0);
    }
  }

  // Typography
  .header-title {
    margin-top: 20px;
  }
  .itinerary-travel-info {
    background-color: rgba(white, 0.9);
    h2 {
      font-size: $font-size-base * 2;
      @media (min-width: $screen-sm-min) {
        font-size: $font-size-base * 3;
      }
    }
    p {
      font-style: normal;
      font-size: $font-size-base * 1.5;
      line-height: 1.5;
      @media (min-width: $screen-sm-min) {
        font-size: $font-size-base * 1.75;
      }
    }
  }

  // Images
  .itinerary-text img,
  button.open-gallery {
    width: 100%;
    margin-top: 30px;
  }

  .author-profile {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $border-color;
  }

  // Map
  .map-column {
    position: absolute;
    top: 0;
    bottom: auto;
    transform: none;
    transition: none;
    z-index: 10;
    overflow: hidden;
    background-color: $color-off-white;
    @media (max-width: $screen-sm-max) {
      transform: none;
      z-index: 1;
      left: 0;
    }
    .itinerary-map {
      background-color: $color-off-white;
    }
    .gm-style-pbc{
      display: none !important
    }
  }

  // Map Markers
/*
  .poi-marker {
    //display: none;
    position: absolute;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: $color-white;
    border: 2px solid $border-color;
    border-radius: 50%;
    transform: rotate(-45deg) translate(25%, -50%);
    //transform-origin: top left;
    &::after {
      //content: '';
      position: absolute;
      top: 5px;
      left: 0px;
      width: 30px;
      height: 30px;
      padding: 5px;
      background-color: $border-color;
      border-radius: 50% 50% 50% 0;
    }
    .icon, img {
      width: 100%;
      height: 100%;
      transform: rotate(45deg);
      fill: $color-brown;
    }
    &:hover {
      z-index: 100 !important;
    }
  }
*/
  .section-dmos {
    background-color: white;
  }
}

// 2020 Recent Stories
.recent-stories {
  border-top: 1px solid $border-color;

  .section-header {
    @media ( min-width: $screen-sm-min ) {
      margin-bottom: 40px;
    }
  }
}

.recent-stories-container {
  padding-top: 30px;
}

.recent-story-highlighted {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 45px;

  @media ( min-width: $screen-sm-min ) {
    margin-bottom: 0;
  }

  .feat-img {
    padding-top: 60%;
  }

  .card-body {
    flex: 1 1 auto;
  }

  &.listing-tasting-note {
    height: auto;
    margin-bottom: 30px;

    border-width: 0 0 1px 0;

    .thumbnail {
      margin: 0;
    }

    h3 {
      margin-top: 0;
    }

    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.recent-story-item {
  display: flex;
  border-top: 1px solid $border-color;
  padding-top: 12px;
  margin-top: 12px;

  .recent-story-thumbnail {
    display: none;

    @media ( min-width: $screen-sm-min ) {
      display: block;
      width: 150px;
      flex-shrink: 0;
    }
  }

  .recent-story-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ( min-width: $screen-sm-min ) {
      padding-left: 12px;
    }
  }

  .feat-img {
    padding-top: 100%;
  }

  .entry-title {
    margin-top: 10px;
  }

  &:first-child {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
}
