.activity-card {
  .thumbnail {
    a {
        padding-top: 25px;
    }

    img {
      width: auto;
      max-width: 200px;
    }
  }
}