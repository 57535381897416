// Page Header

.single-header,
.page-header {
  @include make-row;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: $page-header-bg;
  border-bottom: none;
  color: $page-header-text-color;
  text-align: center;
	background-repeat: no-repeat;
	background-size: 100%;
	background-size: cover;
  background-position: center center;

  .container {
  	position: relative;
  	z-index: 20;
  }

  .overlay {
    z-index: 10;
    pointer-events: none;
  }
}

.page-header {
  h1 {
    margin: 0;
    @media (min-width: $screen-md-min) {
      line-height: 60px;
    }
  }
  .subtitle {
    // FIX ME
    margin-top: 20px;
    margin-bottom: 0;
  }
  .overlay {
    position: absolute;
    background-color: rgba($color-black, 0.8);
    transition: opacity 0.4s cubic-bezier($cubic-bezier);
  }
  &.full-height {
    .overlay {
      opacity: 1;
      background-color: rgba($color-black, 1);
      transition: opacity 0.4s cubic-bezier($cubic-bezier), background-color 1.2s cubic-bezier($cubic-bezier);
    }
    &.images-loaded {
      .overlay {
        background-color: rgba($color-black, 0.8);
      }
    }
  }

  &.page-header-archive {
    background-color: mix($color-off-white, $color-white, 50%);
    color: $text-color;
    text-align: left;
    border-bottom: 1px solid $border-color;
    padding: 70px 0;

    .row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &::before,
      &::after {
        content: none;
      }
    }

    .content-col {
      padding: 30px 30px 30px 15px;
    }

    .label {
      display: inline-block;
      margin-bottom: 15px;
      padding: 0px 15px;
      line-height: 1.5;
      font-size: 1.5em;
      height: 34px;
      font-family: $font-family-brand-sc;
      font-weight: normal;
      background-color: $color-red;
      color: $color-white;
      border-radius: 0;
      vertical-align: middle;

    }

    .label-icon {
      background-color: rgba($color-red, 0.5);
      transition: background-color 0.15s ease-in-out;

      &::before {
        content: '\f104';
        display: inline-block;
        font-weight: 900;
        font-size: 14px/0.7;
        font-family: "Font Awesome 5 Free";
        line-height: 34px;
        vertical-align: top;
        font-size: 25px;
      }
    }

    .archive-link {
      &:hover,
      &:focus {
        .label-icon {
          background-color: $color-red;
        }
      }
    }


    h1 {
      margin-top: 24px;
    }

    .subtitle {
      font-size: 2.8em;
      line-height: 1.3em;
      font-family: $font-family-brand;
      width: 80%;
    }

    .description {
      margin-top: 30px;
      font-size: 1.25em;
      line-height: 1.6em;
      font-family: $font-family-serif;
      font-weight: 500;
    }

    .icon-container {
      margin-top: 24px;
    }

    .image-col {
      min-height: 300px;
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    @media ( min-width: $screen-md-min ) {
      .image-col {
        min-height: 600px;
      }
    }
  }
}
