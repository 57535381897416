// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Colors
// =============================
// Main Colors
$color-dark-green:            #1c5c44;
$color-light-green:           #8ea364;
$color-brown:                 #62514e;
$color-red:                   #aa473d;
$color-orange:                #eb5c25;
$color-seafoam:               #73c2ac;
$color-bronze:                #b58d61;
$color-yellow:                #F9BD19;
$color-blue:                  #074567;

//Details
$color-black:                 #242525;
$color-dark-grey:             #4d4e4f;
$color-mid-grey:              #929495;
$color-light-grey:            #ece4e3;
$color-off-white:             #fcf8ed;
$color-white:                 #ffffff;

// Implementation
$brand-primary:               $color-seafoam;
$brand-secondary:             $color-brown;
$brand-tertiary:              $color-light-green;

$logo-color:                  $color-brown;
$logo-hover-color:            $color-bronze;

$link-color:                  $color-bronze;
$link-active-color:           $color-brown;

$text-color:                  $color-dark-grey;
$title-color:                 $color-brown;

$page-header-bg:              $color-seafoam;
$page-header-text-color:      $color-white;

$button-border:               $color-brown;

$footer-bg:                   $color-black;
$footer-text-color:           $color-mid-grey;
$footer-highlight:            $color-light-grey;

$border-color:                $color-light-grey;
$table-border-color:          $border-color;
$input-border:                $border-color;
$legend-border-color:         $border-color;
$dropdown-border:             $border-color;
$nav-tabs-border-color:       $border-color;
$modal-header-border-color:   $border-color;
$thumbnail-border:            $border-color;
$page-header-border-color:    $border-color;
$hr-border:                   $border-color;

$podcasts-bg:                 $color-dark-green;

$nav-link-hover-bg:           transparent;

$event-header-bg:             $color-orange;

// Typography
// =============================

// Typefaces
$font-family-serif:         "clarendon-text-pro", "Georgia", Georgia, serif;
$font-family-sans-serif:    "PublicaSans", Helvetica, Arial, sans-serif;

$font-family-brand:         "GreatForest", Helvetica, Arial, sans-serif;
$font-family-brand-sc:      "GreatForestSmallCaps", Helvetica, Arial, sans-serif;

// Headings
$headings-font-weight:    600;

$font-size-base: 15px;

// Transitions
// =============================

$cubic-bezier:            .82,.83,.54,.84;

// Buttons
// =============================

$btn-font-weight:                500;

$btn-default-color:              $link-color;
$btn-default-bg:                 $color-white;
$btn-default-border:             $border-color;

/*
$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-success-color:              #fff !default;
$btn-success-bg:                 $brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-info-color:                 #fff !default;
$btn-info-bg:                    $brand-info !default;
$btn-info-border:                darken($btn-info-bg, 5%) !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $brand-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;
*/

$btn-link-disabled-color:        $color-light-grey;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         25px;
$btn-border-radius-large:        30px;
$btn-border-radius-small:        10px;

// Inputs
// =============================

$input-border: $border-color;

$input-border-radius:            0;
$input-border-radius-large:      0;
$input-border-radius-small:      0;

$input-border-focus:             #b58d61;

$input-color-placeholder:        #929495;


// Modals
// =============================

$modal-inner-padding: 20px 30px;
$modal-title-padding: 20px 30px;

// Cards
// =============================
$card-padding: 15px;
