.stories-navigation {
  background: $color-off-white;
  margin: 0 -15px 0 -15px;
  padding: 20px 0;

  .stories-nav {
    font-size: 1.125em;
    margin: 0;

    .menu-item {
      a {
        color: $title-color;
      }

      &.menu-all {
        &::after {
          content: "";
          vertical-align: middle;
          display: inline-block;
          background: $border-color;
          height: 22px;
          width: 2px;
          margin: 0 5px 0 20px;
        }
      }

      &.active {
        font-weight: bold;
      }
    }
  }
}
