
.page-header.page-header-regions {
  padding: 0;
  overflow: hidden;
  // position: relative;

  @media ( min-width: $screen-lg-min ) {
    .container {
      position: relative;

      /*&::before {
        content: "";
        position: absolute;
        top: 0;
        left: 99%;
        width: 9999px;
        width: 100vw;
        bottom: 0;
        display: block;
        background-color: $color-light-green;
      }*/
    }
  }

  .region-map {
    margin-right: -20px;

    @media (min-width: $screen-md-min) {
      margin-right: -30px;

    }
    @media (min-width: $screen-lg-min) {
      margin-right: -45px;
    }
  }

  .page-header-content {
    padding: 70px 0;
  }
}

.region-map-link {
  .cls-2 {
    transition: fill 0.25s ease-in-out;
  }

  &:hover,
  &:focus,
  &.current-region {
    .cls-2 {
      fill: $brand-primary;
    }
  }
}

.region-map {
  svg {
    display: block;
  }

  #Geography {
    @media (max-width: $screen-md-max) {
      display: none;
    }
  }
}

.region-page-nav {
  bottom: 0;
  left: 0;
  // padding-right: 20px;
  // padding-left: 20px;
  border-top: 2px solid $border-color;

  @media (min-width: $screen-md-min) {
    border-top: none;
    position: absolute;
    padding-right: 30px;
    padding-left: 30px;
  }

  @media (min-width: $screen-lg-min) {
    max-width: 1360px;
    padding-right: 45px;
    padding-left: 45px;
  }

  .scroll-prompt {
    position: relative;
    margin-right: 15px;

    a {
      padding-left: 10px !important;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
      background-color: $border-color;
      border-radius: 50%;
    }
  }

  li {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1;

    .icon,
    span {
      vertical-align: middle;
    }

    @media (min-width: $screen-sm-min) {
      display: inline-block;
      margin-left: 0px;
      margin-right: 10px;
    }

    a {
      padding: 24px 10px;
      transition: all 0.2s cubic-bezier($cubic-bezier);
      color: $link-active-color;

      svg {
        fill: $link-active-color;
      }

      &:first-child {
        padding-left: 0;
      }

      &:hover {
        color: $link-color;

        svg {
          fill: $link-color;
        }
      }
    }
  }
}

article.region {
  scroll-margin-top: 66px;
}

.region-dmos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .dmo-logo {
    width: 140px;
    margin: 35px;
  }
}
