/*
 * Breweries & Liquor Stores
*/

// Archive / Grid
// ================================
.search-container {
  position: relative;

  input {
    font-family: $font-family-serif;
    border-top: none;
    border-left: none;
    border-right: none;

    &:focus,
    &:hover:focus {
      box-shadow: none;
    }
  }
}

.features-filter {
  position: relative;
  padding: 15px 0;
  //padding: 30px;
  //border: 2px solid $border-color;
}

.filter-results {
  font-family: $font-family-serif;
  font-size: 20px;
  color: $brand-primary;

  @media (min-width: $screen-sm-min) {
    padding-top: 35px;
    padding-bottom: 15px;
  }
}

.listing-grid {
  padding-top: 15px;
}

.listing-list-container {
  transition: opacity 0.2s cubic-bezier($cubic-bezier);

  &.no-matches {
    opacity: 0.33;

    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }
}

.listing-list {
  @extend .list-unstyled;
  margin-bottom: 30px;
}

.listing-item {
  position: relative;
  margin-bottom: 10px;

  .listing-location,
  .in-ale-trail {
    font-size: 12px;
    text-transform: uppercase;
    color: $color-mid-grey;
    font-weight: 600;
  }

  .in-ale-trail,
  .opening-soon {
    @media (min-width: $screen-sm-min ) {
      &::before {
        content: "\02022";
        padding-left: 2px;
        padding-right: 2px;
        color: $color-brown;
      }
    }
  }

  .in-ale-trail {
    color: $brand-primary;
  }

  .opening-soon {
    color: $color-light-green;
  }

  &.is-participating {
    .icon {
      height: 18px;
      width: 18px;
      vertical-align: text-bottom;
      fill: $color-bronze;

      @media (min-width: $screen-sm-min ) {
        position: absolute;
        top: 1px;
        left: -20px;
      }
    }
  }
}

.listing-title {
  font-size: 15px;
  margin-bottom: 5px;
  color: lighten($text-color, 15%);

  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
}

// Single
// ================================
.page-header-content {
  .listing-location {
    line-height: 1;
    font-size: 18px;
    font-family: $font-family-brand-sc;
    color: lighten($text-color, 25%);
    margin-top: 18px;

    @media (min-width: $screen-sm-min) {
      font-size: 20px;
    }
  }
}

.page-header-gallery {
  position: relative;
  // padding-bottom: 100%;
  padding-bottom: 75%;

  // TODO: make this scale nicely in 1:1
  &::before {
    @include img-retina("../images/bg-dots.png", "../images/bg-dots@2x.png", 600px, 600px);

    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    padding-bottom: 75%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $color-seafoam;
    pointer-events: none;
    z-index: -1;
  }

  .listing-header-image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .video-container {
    max-width: 50cqmin;
    // width: auto;
    margin: auto;
    // position: relative;
    // top: 25cqmin;

    .video-responsive {
      height: 100%;
      width: 100%;
    }
  }
}

.itin.itinerator-carousel {
  .carousel-cell {
    width: 100%;

    @media (min-width: $screen-sm-min) {
      width: 50%;
    }

    @media (min-width: $screen-md-min) {
      width: 30%;
    }
  }
}

.listing-entry {
  .lead {
    text-align: left;
  }
}

// .listing-contact,
// .related-trails-content,
// .contact-box {
//   border: 2px solid;
//   padding: 20px 28px;
//   margin-bottom: 10px;
// }

.listing-notice {
  padding: 20px 28px;
  margin-bottom: 32px;
}

.listing-contact,
.contact-box {
  border-color: $border-color;

  .list-social {
    margin-bottom: 0;
  }
}

.contact-box {
  margin: 32px 0;

  @media (min-width: $screen-sm-min) {
    margin: 38px 0;
  }

  .heading {
    text-transform: none;
    font-weight: 600;
    font-size: 18px;
  }
}

.list-contact {
  @extend .list-unstyled;

  line-height: 25px;
  margin-bottom: 20px;
}

// Listing Features Wrapper
.section-features {
  background-color: $color-off-white;
}

// Listing Features List Container
// .listing-meta {}

.listing-meta-list {
  @extend .list-unstyled;

  margin-bottom: 20px;

  .listing-meta-description {
    font-size: 12px;
    text-transform: uppercase;
    color: $color-mid-grey;
    font-weight: 600;
  }

  .listing-meta-title {
    font-family: $font-family-serif;
    font-size: 18px;
    color: $color-dark-grey;
  }
}

// Feature Groups
.feature-container {
  border-top: 1px solid $border-color;
  padding-top: 12px;
  margin-top: 12px;
}

.parent-feature-container {
  .parent-title {
    margin-bottom: 20px;
  }

  &.parent-important {
    background-color: $color-light-grey;

    .parent-title {
      @extend .alert;

      background-color: $color-yellow;
      color: $color-black;
      margin-bottom: 0px;
    }

    .listing-meta-list {
      padding: 20px;
    }
  }
}

// Additional Notes
.listing-notice {
  border-color: $border-color;
  background-color: $border-color;
  padding-bottom: 8px;

  p {
    color: $color-brown;
    font-size: 15px;
    font-family: $font-family-sans-serif;
  }
}

// Related Content
.listing-tagged-content {
  .thumbnail {
    padding: 0;
    margin-top: 15px;
    margin-bottom: 0;
    line-height: 1;
    background-color: $border-color;
    border: none;
  }
}

.listing-recent-posts,
.listing-tasting-notes,
.listing-story-network {
  &::before {
    content: "";
    display: block;
    border-top: 3px solid $border-color;
    margin-bottom: 14px;
  }

  .thumbnail > img {
    width: 100%;
    object-fit: cover;
  }
}

.listing-recent-posts {
  &::before {
    margin-right: -15px;
  }
}

.listing-tasting-notes,
.listing-story-network {
  &::before {
    margin-left: -15px;
  }
}

.listing-recent-post,
.listing-tasting-note {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid $border-color;

  &:last-child {
    border-bottom: none;
  }

  > a {
    display: block;

    h4,
    h5 {
      transition: color 0.2s cubic-bezier($cubic-bezier);
      color: $text-color;
    }

    &:hover,
    &:focus {

      h4,
      h5 {
        color: $link-color;
      }
    }
  }
}

.listing-recent-post {
  .updated {
    color: lighten($text-color, 25%);
    font-size: 13px;
    margin-top: 0;
  }
}

.listing-tasting-note {
  .subtitle {
    margin: 15px 0 10px 0;
    color: lighten($text-color, 25%);
    font-size: 17px;
  }

  h4 {
    line-height: 1.4;
  }
}

.listing-story-network {
  .amptravel-gallery {
    @media (min-width: $screen-md-min) {
      padding: 0 !important;
    }
  }

  .amptravel-gallery--xxxsmall.amptravel-gallery--single .amptravel-gallery--card-row {
    @media (min-width: $screen-md-min) {
      margin: 0 !important;
      max-width: 100% !important;
    }
  }
}

// Related Ale Trails
.related-trails-header {
  font-weight: normal;
  font-size: 20px;
  color: $color-brown;
  // border-top: 4px solid $border-color;
  margin-top: 34px;
  padding-top: 14px;

  .icon {
    fill: $color-bronze;
    vertical-align: middle;
    margin-bottom: 0.25em;
  }
}

.related-trails-content {
  p {
    @extend .title;
    margin-bottom: 0;
  }

  .carousel & {
    border: 1px solid $border-color;

    .card {
      border: none;
    }
  }

  &.carousel-cell {
    width: 100%;
    display: block;
  }
}

.related-trails-contents.carousel {
  border: 1px solid $border-color;
  margin-bottom: 30px;

  .related-trails-content {
    border: none;
    margin-bottom: 0;
  }

  .flickity-prev-next-button {
    width: 24px;
    height: 24px;

    &:hover,
    &:focus {
      border: 2px solid $link-color;
    }

    &.next {
      right: -12px;
    }

    &.previous {
      left: -12px;
    }
  }
}

// Pager
.paged-nav {
  border-top: 2px solid $border-color;
}

.paged-nav-prev-next {
  padding-top: 30px;
  padding-bottom: 30px;
  float: left;
  width: 50%;

  .title {
    @media (max-width: $screen-xs-max) {
      font-size: 15px;
      line-height: 1.2;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    @media (min-width: $screen-md-min) {
      //max-width: 50%;
    }
  }

  // &.previous {}

  &.next {
    text-align: right;

    .title {
      margin-left: auto;
    }
  }

  &:empty {
    @media (max-width: $screen-xs-max) {
      //display: none;
    }
  }
}

.fun-fact {
  border-left: 12px solid $color-light-grey;
  padding-left: 12px;
  padding-bottom: 1px;
  margin: 18px 0;
  font-size: 14px;
  font-family: $font-family-sans-serif;
  color: $color-brown;

  @media (min-width: $screen-md-min) {
    margin: 32px 0;
  }

  h4 {
    font-size: 0.925em;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.25em;
  }

  p {
    font-size: 1em;
    font-family: $font-family-sans-serif;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Recommended Beers
// ================================

// Section Wrapper
// .section-recommended-beers {}

// Row
.horizontal-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -15px;
}

// Columns
.horizontal-list-col {
  flex: 1 0 100%;
  max-width: 100%;
  padding: 0 15px;

  @media (min-width: $screen-sm-min) {
    flex: 1 0 0;
    max-width: 100%;
  }

  .recommended-beer {
    min-height: 100%;
  }

  .section-header {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;

    h3 {
      color: $color-brown;
    }

    @media (min-width: $screen-sm-min) {
      text-align: left;
      padding-right: 15px;
    }

    a {
      .fa {
        color: $text-color;
        font-size: 1em;
      }
    }
  }
}

// Item content wrapper
.horizontal-list-item {
  position: relative;
  padding: 20px 15px 30px 15px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid $border-color;
  }

  @media (min-width: $screen-sm-min) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -7px;
      bottom: 0;
      right: auto;
      border-top: none;
      border-left: 1px solid $border-color;
    }
  }
}

// Beer Wrapper
.recommended-beer-title,
.award-title {
  color: $color-brown;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
}

// Illustration image adjustments
.recommended-beer-illustration {
  margin: 0 auto 20px auto;

  img {
    margin: 0 auto;
    width: 100px;
  }
}

// Recommended Beer Details
.beer-details {
  margin-top: 8px;

  > span {
    &:last-child {
      border-left: 2px solid $border-color;
      padding-left: 10px;
      margin-left: 8px;

      &:first-child {
        border-left: none;
        padding-left: 0;
        margin-left: 0;
      }
    }
  }

  .abv,
  .ibu {
    font-family: $font-family-serif;
    font-size: 20px;
    font-feature-settings: "onum";

    span {
      font-family: $font-family-sans-serif;
      font-size: 12px;
      font-weight: 700;
      color: $color-mid-grey;
    }
  }
}

.section-awards {
  .section-header {
    margin-bottom: 40px;
  }
}

.awards-carousel {
  @media (min-width: $screen-md-min) {
    height: 300px;
  }

  &:not(.flickity-enabled) {
    .horizontal-list-col:first-child {
      .horizontal-list-item::before {
        content: none;
      }
    }
  }

  &.flickity-enabled {
    display: block;

    .flickity-slider {
      @extend .row;
    }
  }
}

.award-icon {
  position: relative;
  width: 100%;
  height: 160px;
  margin-bottom: 20px;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.award-title {
  display: block;
}

.award-category {
  color: $text-color;
}

.award-beer {
  display: block;
}

.award-year {
  color: $text-color;
  font-family: $font-family-serif;
}

.award-link {
  display: block;

  &:hover,
  &:focus {

    .award-title,
    .award-category,
    .award-year {
      color: $link-hover-color;
    }
  }
}
