// Print Template Styles
.print-style {
  .section-intro {
    padding: 0;
    margin-bottom: 60px;
    .lead {
      margin-bottom: 30px;
    }
  }

  .itinerary-day-header {
    &.sticky, & {
      border-bottom: none !important;
    }
  }

  .itinerary-locations,
  .itinerary-travel-info,
  .itinerary-suggestions,
  .itinerary-day {
    border: none;
  }

  .itinerary-locations,
  .itinerary-suggestions {
    //border-top: 1px dotted black;
  }

  .ale-trail-header {
    padding: 0;
    min-height: 0;
    margin: 45px 0 30px 0;
    background-color: transparent;
    .ale-trail-title,
    .ale-trail-subtitle {
      color: $text-color;
    }
    .ale-trail-subtitle {
      margin-bottom: 0;
    }
  }

  .ale-trail-header-container {
    height: auto;
    min-height: 0;
  }

  .itinerary-day {
    @include clearfix;
  }

  .itinerary-travel-info {
    padding: 30px;
    border: 1px dotted $border-color;
    img {
      border: 1px solid $border-color;
    }
    h2 {
      margin-bottom: 20px;
      text-transform: uppercase;
      background: white !important;
    }
  }

  // Layout
  .itinerary-locations {
    padding-top: 0;
    @include clearfix;
  }

  .itinerary-location {
    //width: 100%;
    //float: left;
    @include clearfix;
    &::before {
      position: static;
      width: auto;
      height: auto;
    }
  }

  .poi-header,
  .poi-description {
    width: 50%;
    float: left;
    margin: 0;
  }

  .itinerary-suggestions {
    width: 100%;
    float: left;
    border: 1px dotted $border-color;
  }

  .poi-icon {
    width: 60px;
    height: 60px;
    padding: 10px;
    &:after {
      position: absolute;
      top: 0;
      left: -6px;
      min-width: 20px;
      height: 20px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 100%;
      text-align: center;
      background-color: white !important;
    }
  }

  .itinerary-location {
    margin-left: 30px;
    margin-bottom: 0;
    padding-top: 15px;
    padding-left: 45px;
    padding-bottom: 15px;
    border-left: 1px dotted $border-color;
    &::after {
      //content: none;
      position: static;
      width: auto;
      height: auto;
    }
    &:first-child {
      padding-top: 30px;
      .poi-icon {
        top: 30px;
      }
    }
    &:last-child {
      padding-bottom: 30px;
    }
    .poi-icon {
      top: 15px;
      left: -30px;
      &:after {
        color: white;
        background: #72c2ac !important;
      }
    }
    &.location-brewery {
      .poi-icon {
        &:after {
          background: #eb5c25 !important;
        }
      }
    }
  }

  .cta-dmo {
    .cta-action {
      .title, > p, & {
        margin-bottom: 0;
      }
    }
  }
}

// Don't show unless print
.print-only {
  display: none;
  .snbc-logo {
    position: absolute;
    top: 0;
    right: 20px;
    width: 75px;
    height: auto;
    a::after {
      content: none;
    }
  }
}

@media print {
  body {
    font-size: 12px;
  }

  .print-only {
    display: block;
  }

  // Things to hide
  #scrollTop,
  .logo-carousel,
  .section-cta,
  .section-share,
  .ale-trail-header > video,
  button[data-target="#watchVideo"],
  button.view-map {
    display: none !important;
  }

  .banner {
    position: static !important;

    .navigation-top {
      display: block !important;
    }

    .navigation-bottom,
    .nav-sitemap,
    .nav-about,
    .nav-connect {
      display: none !important;
    }

    .brand {
      &::after {
        content: attr(href);
        text-align: center;
        display: block;
        font-size: 10px;
        text-transform: uppercase;
      }
    }
  }

  a[href]::after {
    word-break: break-all;
  }

  .print-style {
    a[href^='//'] {
      &::after {
        content: none;
      }
    }
    a:not([href^='//']) {
      //background-color:lightgreen !important;
    }
    a[href*=bcaletrail\.ca] {
      &::after {
        content: none;
      }
    }
  }

  .poi-contact-info,
  .poi-title,
  .copyright-info {
    a {
      &::after {
        content: none;
      }
    }
  }

  p {
    font-size: 12px;

    a {
      text-decoration: underline;
    }
  }

  // Ale Trails
  .single-ale-trails {
    .section-intro {
      padding-left: 30px;
      padding-right: 30px;
    }

    .content-info {
      .snbc-logo,
      .primary-nav,
      .secondary-nav {
        display: none !important;
      }
    }

    .list-social {
      > li {
        display: block;
        margin-bottom: 4px;
      }
      a {
        font-weight: normal;
        &::after {
          font-size: 11px;
        }
      }
    }

    .dmo-logo {
      width: 200px !important;
    }

    .ale-trail-header-container {
      height: auto;
      min-height: 0;
      .snbc-logo {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .itinerary-day {
      page-break-before: always;
    }

    .itinerary-day-header {
      &.sticky, & {
        width: 100%;
        position: static !important;
      }
    }

    .itinerary-travel-info,
    .itinerary-location {
      page-break-inside: avoid;
      a {
        &::after {
          content: none;
        }
      }
    }

    .itinerary-travel-info {
      border: 1px dotted black !important;
      img {
        border: 1px solid black !important;
      }
    }

    .itinerary-locations {
      padding-bottom: 0;
    }

    .itinerary-location {
      margin-left: 30px;
      margin-bottom: 0;
      padding-left: 45px;
      padding-bottom: 30px;
      border-left: 1px solid dotted !important;
      .poi-icon {
        width: 60px;
        height: 60px;
        left: -30px;
        background: white !important;
        border: 1px solid black;
        padding: 0;
        svg {
          width: 60%;
          height: 60%;
          margin-left: 20%;
          margin-top: 20%;
          fill: black !important;
        }
      }
    }

    .itinerary-suggestions {
      border: 1px dotted black !important;
    }

    .cta-dmo {
      .cta-action {
        display: table-cell;
        vertical-align: middle;
        margin-bottom: 0;
        width: 33.3333%;
      }
      a {
        padding: 5px;
        border: none !important;
        text-decoration: underline;
        &::after {
          content: none;
        }
      }
    }
  }

  // Road Trip Journals (tasting-notes)
  .single-tasting-notes {
    .tasting-notes-banner {
      .brand {
        &::after {
          content: none;
        }
      }

      .list-social {
        display: none;
      }
    }

    .article-header {
      &.pinned {
        position: static !important
      }

      .tasting-notes-days-nav {
        display: none;
      }
    }

    .itinerary-column {
      float: none;
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      .info-photo {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .itinerary-day {
      page-break-before: always;
    }

    .map-column {
      display: none;
    }

    .author-profile-name {
      > a::after {
        content: none;
      }
    }

    .author-profile-social {
      .url {
        width: auto;
        height: auto;
      }
    }

    .dmo-logos .dmo-logo {
      width: 30%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
