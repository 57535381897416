.newsletter-modal {
  margin-top: 100px;

  @media (max-width: $screen-md-min) {
    margin-top: 20px;
  }

  .modal-dialog {
    min-height: 300px;
  }

  .modal-content {
    border: 0;
    color: $color-white;
    position: relative;
    overflow: hidden;

    .close {
      color: $color-white;
      font-size: 2em;
      opacity: 1;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 0.5em;
      top: 0.25em;
      z-index: 1;
      font-weight: normal;
    }

    h1 {
      margin-bottom: 0.5em;
      color: $color-white;
      font-size: 2em;
    }

    .row {
      min-height: 250px;
      margin: 0;
      align-items: center;
      display: flex;
    }

    .image-block {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      height: 450px;
      flex: 1;

      @media (max-width: $screen-md-min) {
        display: none;
      }
    }

    .form-content {
      padding: 20px;
    }

    #mc_embed_signup {
      margin-top: 20px;

      .button {
        @extend .btn-default;
        @extend .btn-square;

        display: block;
        font-size: 1.2em;
        margin-top: 15px;
        width: 100%;
        padding: 1rem 30px;
        border: 0;
      }

      .mc-field-group {
        display: flex;
        margin-bottom: 5px;

        @media (max-width: $screen-md-min) {
          display: block;
        }

        label {
          margin-right: 5px;
          width: 35%;
          text-align: left;

          @media (max-width: $screen-md-min) {
            width: 100%;
          }
        }
      }

      input[type="text"],
      .email {
        border: 0;
        width: 100%;
        color: $color-black;
      }
    }
  }

  .modal-content {
    background: $color-black;
    border-radius: 0;
  }
}
